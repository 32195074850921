import * as yup from "yup";

import {
  validationTextField,
  validationStringSelect,
  validationBooleanSelect,
  validationNumberWithoutDecimal
} from "./defaultValidations";

export default yup.object({
  paramSystemName: validationTextField("Param System Name", 3, 127),
  frontendTitle: validationTextField("Frontend Title", 3, 127),
  // helpNotation: validationTextField("Help Notation", 3, 127),
  orderPosition: validationNumberWithoutDecimal("Order Position", 1, 127),
  isMandatory: validationBooleanSelect("Is Mandatory"),
  fieldType: validationStringSelect("Field Type"),
  // paramParent: validationStringSelect("Param Parent"),
  // paramValue: validationStringSelect("Param Value"),
  // defaultValue: validationStringSelect("Default Value"),
  status: validationStringSelect("Status"),
});
