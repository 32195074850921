import { Box } from "@mui/system";
import ParamsField from "./ParamsField";
import ParamsTable from "./ParamsTable";
// import { dndValue } from "../../utils/datajson/dndVal";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import {getBodyTblForCheckPrm } from "../../store/feature/endUser";
import { useParams, useSearchParams } from "react-router-dom";
import { getParamsById } from "../../store/feature/params";

const Params = () => {
  const dispatch = useAppDispatch()
  const [defaultValues, setDefaultValues] = useState([]);
  const [dataTableParam, setDataTableParam] = useState<any>('');
  const [enableButton, setEnableButton] = useState<boolean>(false);
  
  const [param] = useSearchParams()
  const uid = param.get('param-uid')
  const id = param.get('param-id')
  const { formType } = useAppSelector(
    (state) => state.user
  );
  const checkData = async ()=> {
    let checkDataButton = await dispatch(getBodyTblForCheckPrm(uid));
    if(checkDataButton.payload.data !== null){
      setEnableButton(true);
    }
    dispatch(getParamsById(id));
  }

  useEffect(() => {
    if(Object.keys(formType).length !== 0){
      checkData()
    }
  }, [formType])


  return (
    <>
      <ParamsField
        defaultValues={defaultValues}
        setDefaultValues={setDefaultValues}
        dataTableParam={dataTableParam}
        // button={enableButton}
        button={false}
        setDataTableParam={setDataTableParam}
      />
      <ParamsTable
        // button={enableButton}
        button={false}
        dataTableParam={dataTableParam}
        setDataTableParam={setDataTableParam}
      />
    </>
  );
};

export default Params;
