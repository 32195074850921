import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../service/instance";

interface IFormGroup {
  isLoadingType: boolean;
  isLoadingEvent: boolean;
  dataFType: any;
  editDataFType: any;
  formTypeSelect: any;
  formTypeSelectId: any;
  totalRecFType: any;
  msgSccsFTy: string;
}

const initialState: IFormGroup = {
  isLoadingType: false,
  isLoadingEvent: false,
  dataFType: [],
  editDataFType: {},
  formTypeSelectId: [],
  formTypeSelect: [],
  totalRecFType: 0,
  msgSccsFTy: "",
};

export const getFormType = createAsyncThunk<any, any, { rejectValue: any }>(
  "formType/list",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.formType.uc_adm_pge_access_uid;
      const resp: any = await instance.get("/form-types", {
        params: data,
        headers: {
          'page-access-uid': uid,
        },
      });
      let dataFType = resp.data.data;
      let formTypeOpt = dataFType.data.map((e: any) => ({
        label: e.uc_frm_type_name,
        value: e.uc_frm_type_uid,
        id: e.uc_frm_type_id,
      }));
      let formTypeOptId = dataFType.data.map((e: any) => ({
        label: e.uc_frm_type_name,
        value: e.uc_frm_type_id,
      }));
      // console.log('formtypeid ===> ', formTypeOptId)
      let totalRecords = resp.data.data.total_records;
      return { dataFType, formTypeOpt, totalRecords, formTypeOptId };
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const getFormTypeId = createAsyncThunk<any, any, { rejectValue: any }>(
  "formType/list/id",
  async (id, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.formType.uc_adm_pge_access_uid;
      const resp: any = await instance.get(`/form-type?formTypeUID=${id}`, {
        headers: {
          'page-access-uid': uid,
        },
      });

      console.log("========> data form types ", resp.data);

      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const createFormType = createAsyncThunk<any, any, { rejectValue: any }>(
  "formType/create",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.formType.uc_adm_pge_access_uid;
      const resp: any = await instance.post("/form-type", data, {
        headers: {
          'page-access-uid': uid,
        },
      });
      // console.log('form Type create ====> ', data)
      // console.log('form Type create  response ====> ', resp.data)
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const updateFormType = createAsyncThunk<any, any, { rejectValue: any }>(
  "formType/update",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.formType.uc_adm_pge_access_uid;
      const resp: any = await instance.put(
        `/form-type/${data.uc_frm_type_uid}`,
        data,
        {
          headers: {
            'page-access-uid': uid,
          },
        }
      );
      // console.log(data, ' =========> isi data')
      // console.log(resp.data, " ========> data update form types");
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const updateFormTypeDeleted = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>(
  "formType/updateDeleted",
  async (data, { rejectWithValue, dispatch, getState }) => {
    console.log("data here", data);
    try {
      const state: any = getState();
      const uid = state?.user.formType.uc_adm_pge_access_uid;
      const { uc_frm_type_uid, uc_frm_type_status } = data;
      const resp = await instance.put(
        `/form-type/${uc_frm_type_uid}`,
        {
          uc_frm_type_status,
        },
        {
          headers: {
            'page-access-uid': uid,
          },
        }
      );
      // console.log(data, ' =========> isi data')
      // console.log(resp.data, " ========> data update form types");
      dispatch(getFormType({ pagesize: 10 }));
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const formTypeSlice = createSlice({
  name: "formGroup",
  initialState,
  reducers: {
    setEditDataType: (state, action) => {
      state.editDataFType = action.payload;
    },
    rmvMsgSccsFTy: (state) => {
      state.msgSccsFTy = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getFormType.pending, (state) => {
        state.isLoadingType = true;
      })
      // error ada disini bro
      .addCase(getFormType.fulfilled, (state, action) => {
        state.isLoadingType = false;
        const { dataFType, formTypeOpt, totalRecords, formTypeOptId } =
          action.payload;
        // console.log('from extrareducer form type ====> ', {dataFType, formTypeOpt, totalRecords } )
        state.dataFType = dataFType.data;
        state.formTypeSelect = formTypeOpt;
        state.formTypeSelectId = formTypeOptId;
        state.totalRecFType = totalRecords;
      })
      .addCase(getFormType.rejected, (state) => {
        state.isLoadingType = false;
      })
      .addCase(getFormTypeId.pending, (state) => {
        state.isLoadingType = true;
      })
      .addCase(getFormTypeId.fulfilled, (state, action) => {
        state.isLoadingType = false;
        state.editDataFType = action.payload.data;
      })
      .addCase(getFormTypeId.rejected, (state) => {
        state.isLoadingType = false;
      })

      .addCase(createFormType.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(createFormType.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
        state.msgSccsFTy = action.payload.msg;
      })
      .addCase(createFormType.rejected, (state) => {
        state.isLoadingEvent = false;
      })

      .addCase(updateFormType.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(updateFormType.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
        state.msgSccsFTy = action.payload.msg;
      })
      .addCase(updateFormType.rejected, (state) => {
        state.isLoadingEvent = false;
      })

      .addCase(updateFormTypeDeleted.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(updateFormTypeDeleted.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
      })
      .addCase(updateFormTypeDeleted.rejected, (state) => {
        state.isLoadingEvent = false;
      });
  },
});

export const { setEditDataType, rmvMsgSccsFTy } = formTypeSlice.actions;
export default formTypeSlice.reducer;
