import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import serviceMng from "../../utils/validation/serviceMng";
import {
  InputForm,
  TextAreaForm,
  SelectFormm,
} from "../../components/molecules/forms/index";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SMFieldTable from "./SMFieldTable";
import SMServiceParam from "./SMServiceParam";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import {
  createServMng,
  getServMngDefByDefntId,
  getServMngDefByFrmTypeId,
  getServMngTable,
  updateServMng,
} from "../../store/feature/servMng";
import { getFormType } from "../../store/feature/formType";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";

const SMField = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const id = params.get("srvc-definition-uid");
  const {
    optionServMng,
    servDefUpdate,
    servDefParams,
    isLoadingServMng,
    isLoadingEvent,
  } = useAppSelector((state: RootState) => state.servMng);
  const { isLoadingType } = useAppSelector((state: RootState) => state.fType);
  const { srvMng } = useAppSelector((state: RootState) => state.user);

  const { formTypeSelect, formTypeSelectId } = useAppSelector(
    (state: RootState) => state.fType
  );

  const [rows, setRows] = useState<Array<Object>>([]);

  const editServMng = pathname.includes("edit");
  const methods = useForm<{
    id: number;
    uid: string;
    serviceName: string;
    endpointUrl: string;
    description: string;
    parentService: string;
    status: string;
    mainForm: number;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(serviceMng),
    defaultValues: {
      id: 0,
      uid: "",
      serviceName: "",
      endpointUrl: "",
      description: "",
      parentService: "",
      status: "",
      mainForm: 0,
    },
  });

  const {
    form_type_id,
    form_type_uid,
    service_definition_Id,
    service_definition_created_at,
    service_definition_description,
    service_definition_endpoint_url,
    service_definition_name,
    service_definition_parent_id,
    service_definition_status,
    service_definition_updated_at,
    service_definition_parameters,
  }: any = servDefUpdate;
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];
  useEffect(() => {
    if (Object.keys(srvMng).length !== 0) {
      dispatch(getServMngTable({ pagesize: 100000 })); // for Parent Service
      dispatch(getFormType({ pagesize: 100000 })); // for Main form
      if (editServMng) {
        dispatch(getServMngDefByDefntId(id));
      }
    }
  }, [srvMng]);

  useEffect(() => {
    if (editServMng) {
      // dispatch(get)
      reset({
        serviceName: service_definition_name,
        endpointUrl: service_definition_endpoint_url,
        description: service_definition_description,
        parentService: service_definition_parent_id,
        status: service_definition_status?.toUpperCase(),
        mainForm: form_type_id,
      });
      if (form_type_uid !== undefined) {
        dispatch(getServMngDefByFrmTypeId(form_type_uid));
      }
      setRows(servDefParams);
    } else {
      reset({});
    }
  }, [servDefUpdate]);

  const onSubmit = (e: any) => {
    let newData = rows.map((val: any) => ({
      form_param_id: Number(val.parameterService),
      form_param_id_join: Number(val.joinedParams),
      form_type_id: Number(val.joinedForm),
      service_parameter_sequence: Number(val.squence),
      service_parameter_datatype: val.dataType,
    }));
    let formData = {
      form_type_id: Number(e.mainForm),
      service_definition_description: e.description,
      service_definition_endpoint_url: e.endpointUrl,
      service_definition_name: e.serviceName,
      service_definition_parameters: [{}],
      // service_definition_status: e.status,
      service_definition_parent_id: Number(e.parentService),
    };
    let editFormData = {
      service_definition_uid: id,
      form_type_id: Number(e.mainForm),
      service_definition_description: e.description,
      service_definition_endpoint_url: e.endpointUrl,
      // service_definition_parameters: [{}],
      service_definition_name: e.serviceName,
      // service_definition_parameters: newData,
      service_definition_status: e.status,
      service_definition_parent_id: Number(e.parentService),
    };
    if (editServMng) {
      dispatch(updateServMng(editFormData));
      navigate("/service-management");
    } else {
      dispatch(createServMng(formData));
      navigate("/service-management");
    }
  };

  const handleMainForm = (e: any) => {
    dispatch(getServMngDefByFrmTypeId(e.value));
  };

  return (
    <DetailDataSkeleton
      isLoading={isLoadingServMng || isLoadingType}
      isLoadingEvent={isLoadingEvent}
      inputCount={6}
    >
      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h3" fontFamily="Open Sans" marginY="20px">
            {editServMng
              ? "Update Service Management"
              : "Create Service Management"}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputForm
                name="serviceName"
                label="Service Name"
                errors={errors}
                control={control}
                required
                placeholder="Type Service Name Here....."
                maxLength={100}
                isLoading={false}
              />
              <InputForm
                name="endpointUrl"
                label="Endpoint Url"
                errors={errors}
                control={control}
                required
                placeholder="Type Enpoint Url Here....."
                maxLength={100}
                isLoading={false}
              />
              <TextAreaForm
                name="description"
                label="Description"
                errors={errors}
                control={control}
                placeholder="Type Description Here....."
                maxLength={100}
                isLoading={false}
                defaultValue={""}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectFormm
                name="parentService"
                label="Parent Service"
                defaultValue={1}
                options={optionServMng}
                placeholder="None"
                disabled={false}
                isLoading={false}
                control={control}
                errors={errors}
                isClear
              />
              <SelectFormm
                name="status"
                label="Status"
                defaultValue={1}
                options={optStatusSelect}
                placeholder="None"
                disabled={false}
                isLoading={false}
                control={control}
                errors={errors}
                required
              />
              <SelectFormm
                name="mainForm"
                label="Main Form"
                defaultValue={1}
                options={formTypeSelectId}
                placeholder="None"
                disabled={false}
                isLoading={false}
                control={control}
                errors={errors}
                required
                // onChangeForm={handleMainForm}
              />
            </Grid>
          </Grid>
          {/* <SMFieldTable rows={rows} setRows={setRows} /> */}
          {/*  datagrid table  */}
          {/* <SMServiceParam rows={rows} setRows={setRows} /> */}
          <Grid container>
            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                marginTop={5}
              >
                <Button
                  variant="contained"
                  color="gray"
                  onClick={() => navigate("/service-management")}
                >
                  Cancel
                </Button>
                <Button variant="contained" type="submit">
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </DetailDataSkeleton>
  );
};

export default SMField;
