import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Stack, Typography, IconButton, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  InputForm,
  RadioForm,
  SelectFormm,
} from "../../components/molecules/forms";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import params from "../../utils/validation/params";
import { optFieldType } from "../../constants/params";
import {
  getParamsById,
  resetDataParamId,
  getParamsByIdForParamForm,
  createParamsId,
  updateFormParamsId,
} from "../../store/feature/params";
import {
  createOptValue,
  deleteOptValue,
  getOptValById,
  getOptValByParamId,
} from "../../store/feature/optionValue";
import HiddenParamsField from "./HiddenParamsField";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// icons
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const ParamsField = ({
  defaultValues,
  setDefaultValues,
  dataTableParam,
  setDataTableParam,
  button,
}: any) => {
  // console.log("defval param fields =====> ", defaultValues);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [param] = useSearchParams();
  const id = param.get("param-id");
  // console.log('id here ===> ', id)
  const {
    optParentParam,
    dataParamId,
    frmTypeId,
    paramIdCreateP,
    dataUpdateFormParam,
    isLoadingParams,
  } = useAppSelector((state: RootState) => state.param);
  const { optParamVal, optParamValHide } = useAppSelector(
    (state) => state.optVal
  );
  const { formType } = useAppSelector(
    (state) => state.user
  );
  const [hiddenParam, setHiddenParam] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm<{
    paramSystemName: string;
    frontendTitle: string;
    helpNotation: string;
    orderPosition: string;
    isMandatory: string;
    fieldType: string;
    paramParent: number;
    paramValue: number;
    defaultValue: string;
    status: string;
    funcMenu: number;
    param: number;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(params),
    // shouldUnregister: false,
    defaultValues: {
      paramSystemName: "",
      frontendTitle: "",
      helpNotation: "",
      orderPosition: "",
      isMandatory: "",
      fieldType: "",
      paramParent: 0,
      paramValue: 0,
      defaultValue: "",
      status: "",
      funcMenu: 0,
      param: 0,
    },
  });

  const {
    uc_frm_param_default_value,
    uc_frm_param_field_type,
    uc_frm_param_help_notation,
    uc_frm_param_id,
    uc_frm_param_is_mandatory,
    uc_frm_param_name,
    uc_frm_param_order_position,
    uc_frm_param_parent,
    uc_frm_param_ref_other_data,
    uc_frm_param_ref_other_data_child,
    uc_frm_param_status,
    uc_frm_param_title,
    uc_frm_param_uid,
    uc_frm_type_id,
  } = dataParamId;

  console.log("dataParamId ===> ", dataParamId);
  useEffect(() => {
    dispatch(resetDataParamId());
  }, []);

  // get paramid for update input value

  useEffect(() => {
    setOpen(false);
    if (
      uc_frm_param_ref_other_data_child !== null &&
      uc_frm_param_ref_other_data_child !== undefined
    ) {
      console.log("hahaha ===> ", uc_frm_param_ref_other_data_child);
      dispatch(
        getParamsByIdForParamForm(
          uc_frm_param_ref_other_data_child?.uc_frm_type_id
        )
      );
      // console.log('hahaha', uc_frm_param_ref_other_data_child)
      reset({
        paramSystemName: uc_frm_param_name,
        frontendTitle: uc_frm_param_title,
        helpNotation: uc_frm_param_help_notation,
        orderPosition: uc_frm_param_order_position,
        isMandatory: uc_frm_param_is_mandatory?.toString(),
        fieldType: uc_frm_param_field_type,
        paramParent: uc_frm_param_parent,
        paramValue: 0,
        defaultValue: uc_frm_param_default_value,
        status: uc_frm_param_status,
        funcMenu: uc_frm_param_ref_other_data_child?.uc_frm_type_id,
        param: uc_frm_param_ref_other_data,
      });
      console.log("param ===> ", uc_frm_param_ref_other_data);
      console.log(
        "funcMenu ===> ",
        uc_frm_param_ref_other_data_child?.uc_frm_type_id
      );
    } else {
      reset({
        paramSystemName: uc_frm_param_name,
        frontendTitle: uc_frm_param_title,
        helpNotation: uc_frm_param_help_notation,
        orderPosition: uc_frm_param_order_position,
        isMandatory: uc_frm_param_is_mandatory?.toString(),
        fieldType: uc_frm_param_field_type,
        paramParent: uc_frm_param_parent,
        paramValue: 0,
        defaultValue: uc_frm_param_default_value,
        status: uc_frm_param_status,
      });
    }
    if (uc_frm_param_parent !== "" && uc_frm_param_parent !== undefined) {
      console.log("uc_frm_param_parent ===> ", uc_frm_param_parent);
      dispatch(getOptValById(uc_frm_param_parent));
    }
    if (uc_frm_param_id !== null && uc_frm_param_id !== undefined) {
      dispatch(getOptValByParamId(uc_frm_param_id));
    }
    handleFieldType({ value: uc_frm_param_field_type });
    if (dataParamId.length !== 0) {
      console.log(
        "console from data paramId length",
        uc_frm_param_ref_other_data_child
      );
      if (uc_frm_param_ref_other_data_child === null) {
        setOpen(false);
      } else if (
        uc_frm_param_ref_other_data_child.uc_frm_type_id !== 0 &&
        uc_frm_param_ref_other_data_child.uc_frm_type_name !== null
      ) {
        setOpen(true);
      }
    }
  }, [dataParamId, formType]);

  // for defauilt value

  useEffect(() => {
    setDefaultValues(optParamValHide);
  }, [optParamValHide]);

  // check option param from submit and create it

  useEffect(() => {
    if (hiddenParam) {
      let newOptVal = defaultValues.map((e: any, index: number) => ({
        uc_frm_opt_val_name: e.value,
        uc_frm_opt_val_order_position: index,
        uc_frm_opt_val_parent: getValues("paramValue"),
        uc_frm_opt_val_status: "Active",
        uc_frm_param_id: paramIdCreateP,
      }));
      dispatch(createOptValue(newOptVal));
    }
  }, [paramIdCreateP]);

  // udpate and delete option value for update form param

  useEffect(() => {
    if (!open) {
      let valDeleted = defaultValues
        .filter((e: any) => e.isDeleted === true)
        .map((e: any) => Number(e.value));
      let valNotDelete = defaultValues
        .filter((e: any) => e.isDeleted === false)
        .map((e: any, index: number) => ({
          uc_frm_opt_val_name: e.value,
          uc_frm_opt_val_order_position: index,
          uc_frm_opt_val_parent: getValues("paramValue"),
          uc_frm_opt_val_status: "Active",
          uc_frm_param_id,
        }));

      if (valNotDelete.length !== 0) {
        dispatch(createOptValue(valNotDelete));
      }
      if (valDeleted.length !== 0) {
        dispatch(deleteOptValue({ uc_frm_param_id: valDeleted }));
      }
    }
  }, [dataUpdateFormParam]);

  // create option value

  const onSubmit = (e: any) => {
    console.log("e.isMandatory ===> ", e.isMandatory ? 1 : 0);
    let mandatory = e.isMandatory ? 1 : 0;
    let other = e.param === undefined ? 0 : e.param;
    let newDispatch = {
      uc_frm_param_default_value: e.defaultValue,
      uc_frm_param_field_type: e.fieldType,
      uc_frm_param_help_notation: e.helpNotation,
      uc_frm_param_is_mandatory: mandatory,
      uc_frm_param_name: e.paramSystemName,
      uc_frm_param_order_position: Number(e.orderPosition),
      uc_frm_param_parent: e.paramParent,
      uc_frm_param_ref_other_data: other,
      uc_frm_param_status: e.status,
      uc_frm_param_title: e.frontendTitle,
      uc_frm_type_id: Number(id),
    };

    // console.log('ada table row ===> ', defaultValues.map((e: any, index: number)=> ({
    //   uc_frm_opt_val_name: e.value,
    //   uc_frm_opt_val_order_position: index,
    //   uc_frm_opt_val_parent: 0,
    //   uc_frm_opt_val_status: "Active",
    //   uc_frm_param_id: 0
    // })))
    if (dataParamId.length === 0) {
      dispatch(createParamsId(newDispatch));
      console.log("create => params ===> ");
    } else {
      console.log("edit => params ===> ");
      dispatch(
        updateFormParamsId({
          ...newDispatch,
          uc_frm_param_uid: dataParamId.uc_frm_param_uid,
        })
      );
    }
  };

  const optIsMandatory = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];

  // TODO
  // belum bisa clear kalo misalkan statusnya di hapus atau di balikin biar gak ada filter statusnya
  // belum menambahkan loading di semua page

  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "Active",
    },
    {
      label: "INACTIVE",
      value: "Inactive",
    },
  ];

  const handleChangeParamParent = (e: any) => {
    dispatch(getOptValById(e.value));
  };

  const handleFieldType = (e: any) => {
    // console.log(' ada =========> ', e)
    // console.log('open from field type ===> ', open)
    setOpen(false);
    if (
      e.value === "02" ||
      e.value === "03" ||
      e.value === "09" ||
      e.value === "10"
    ) {
      setHiddenParam(true);
      return;
    }
    setHiddenParam(false);
  };

  const handleReset = async (e: any) => {
    console.log(" from handle reset =========> ");
    // e.preventDefault();
    await reset({});
    setOpen(false);
    setHiddenParam(false);
    // Call reset again to reset the form a second time
    await reset({});
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <Typography variant="h3" fontFamily="Open Sans" marginY="20px">
        <IconButton aria-label="back" onClick={() => navigate("/form-type")}>
          <KeyboardBackspaceIcon />
        </IconButton>{" "}
        Add Params
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputForm
            name="paramSystemName"
            label="Param System Name"
            errors={errors}
            control={control}
            required
            placeholder="Type Param System Name Here....."
            maxLength={100}
            isLoading={false}
          />
          <InputForm
            name="frontendTitle"
            label="Frontend Title"
            errors={errors}
            control={control}
            required
            placeholder="Type Frontend Title Here....."
            maxLength={100}
            isLoading={false}
          />
          <InputForm
            name="helpNotation"
            label="Help Notation"
            errors={errors}
            control={control}
            placeholder="Type Help Notation Here....."
            maxLength={200}
            isLoading={false}
          />
          <InputForm
            name="orderPosition"
            label="Order Position"
            errors={errors}
            control={control}
            placeholder="Type Order Position Here....."
            maxLength={100}
            isLoading={false}
            type="number"
            required
          />
          {/* Radio is Mandatory here */}
          <SelectFormm
            name="fieldType"
            label="Field Type"
            defaultValue={1}
            options={optFieldType}
            placeholder="None"
            disabled={false}
            isLoading={false}
            control={control}
            errors={errors}
            onChangeForm={handleFieldType}
            required
          />
          {hiddenParam && (
            <HiddenParamsField
              defaultValues={defaultValues}
              setDefaultValues={setDefaultValues}
              control={control}
              errors={errors}
              watch={watch}
              setValue={setValue}
              open={open}
              setOpen={setOpen}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <SelectFormm
            name="paramParent"
            label="Param Parent"
            defaultValue={1}
            options={optParentParam}
            placeholder="None"
            disabled={false}
            isLoading={false}
            control={control}
            errors={errors}
            isSearch
            onChangeForm={handleChangeParamParent}
          />
          <SelectFormm
            name="paramValue"
            label="Parent Option Value"
            defaultValue={1}
            options={optParamVal}
            placeholder="None"
            disabled={false}
            isLoading={false}
            control={control}
            errors={errors}
          />
          <InputForm
            name="defaultValue"
            label="Default Value"
            errors={errors}
            control={control}
            placeholder="Type Default Value Here....."
            maxLength={100}
            isLoading={false}
          />
          <RadioForm
            name="isMandatory"
            label="Is Mandatory"
            options={optIsMandatory}
            defaultValue={0}
            errors={errors}
            control={control}
            required
          />
          <SelectFormm
            name="status"
            label="Status"
            defaultValue={1}
            options={optStatusSelect}
            placeholder="None"
            disabled={false}
            isLoading={false}
            control={control}
            errors={errors}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button
              variant="contained"
              color="gray"
              onClick={handleReset}
              disabled={button}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={button}
            >
              Submit
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ParamsField;
