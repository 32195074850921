import FormGroup from "../pages/formGroup/FormGroup";
import FormGroupField from "../pages/formGroup/FormGroupField";
import IRoutes from "../interfaces/IRoutes";
import NotFound from "../pages/notFound/NotFound";

// const FeedPage = React.lazy(() => import('../pages/feed/Feed'));

const FormGRoupRoutes: Array<IRoutes> = [
  {
    element: <FormGroup />,
    path: "/form-group",
  },
  {
    element: <FormGroupField />,
    path: "/form-group/create",
  },
  {
    element: <FormGroupField />,
    path: "/form-group/edit/:id",
  }
];

const FormGRoupRoutesErr: Array<IRoutes> = [
  {
    element: <NotFound />,
    path: "/form-group",
  },
  {
    element: <NotFound />,
    path: "/form-group/create",
  },
  {
    element: <NotFound />,
    path: "/form-group/edit/:id",
  }
];

export {FormGRoupRoutes, FormGRoupRoutesErr };
