import { Box, Button, Chip, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector, RootState } from "../../../store";
import PaginationControl from "../pagination/Pagination";
import { useNavigate } from "react-router-dom";
import {
  getFormType,
  updateFormTypeDeleted,
} from "../../../store/feature/formType";
import { getParamsById, setFormTypeId } from "../../../store/feature/params";
import Swal from "sweetalert2";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect } from "react";
import { getBodyTblForCheckPrm } from "../../../store/feature/endUser";
import { useTheme } from "@mui/material/styles";

const FormTypesTable = ({ params, setParams, setDefaultValue }: any) => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dataFType, totalRecFType } = useAppSelector(
    (state: RootState) => state.fType
  );
  const { formType } = useAppSelector((state: RootState) => state.user);

  useEffect(() => {
    if (Object.keys(formType).length !== 0) {
      dispatch(getFormType({ pagesize: 10 }));
    }
  }, [formType]);
  const styles = {
    root: {
      "& .MuiDataGrid-renderingZone": {
        overflowX: "auto",
        overflowY: "hidden",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "@media (min-width: 600px)": {
          overflowX: "scroll",
          overflowY: "hidden",
          scrollbarWidth: "thin",
          msOverflowStyle: "none",
        },
      },
    },
  };

  const checkForDeleteParams = async (e: any) => {
    let ada = await dispatch(getBodyTblForCheckPrm(e));
    let newDelete = {
      uc_frm_type_uid: e,
      uc_frm_type_status: "deleted",
    };
    if (ada.payload.data === null) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("newDelete ===> ", newDelete);
          dispatch(updateFormTypeDeleted(newDelete));
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: palette.primary.main,
            text: "Your file has been deleted.",
            didOpen: () => Swal.getConfirmButton()?.focus(),
          });
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sorry this form can not be deleted because a data has been inputed!",
        didOpen: () => Swal.getConfirmButton()?.focus(),
      });
    }
  };

  const customCellRender = (params: any) => {
    // Button for Edit
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(
        `/form-type/edit?param-id=${params.id}&param-uid=${params.row.uid}`
      );
    };
    // Button for Params
    const onParams = async (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      // nonactive only for testing
      // let ada = await dispatch(getBodyTblForCheckPrm(params.row.uid));
      // if (ada.payload.data !== null) {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Oops...",
      //     text: "Sorry these params can not be add, edit or deleted because a data has been inputed!",
      //   });
      // }

      dispatch(setFormTypeId(params.id));
      dispatch(getParamsById(params.id));
      navigate(`/params?param-id=${params.id}&param-uid=${params.row.uid}`);
    };
    // Button for Delete
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      checkForDeleteParams(params.row.uid);
    };
    // Button for Preview
    const onPreview = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      console.log("console from preview ======> ", params.id);
      // dispatch(getEndUserPage(params.id))
      navigate(`/form-type/preview/${params.row.uid}`);
    };

    return (
      <Stack spacing={2} direction="row">
        {formType.uc_adm_pge_acs_update === 1 && (
          <Button variant="contained" onClick={onEdit}>
            Update
          </Button>
        )}
        <Button variant="contained" color="gray" onClick={onParams}>
          Params
        </Button>
        {formType.uc_adm_pge_acs_delete === 1 && (
          <Button variant="contained" color="error" onClick={onDelete}>
            Delete
          </Button>
        )}
        <Button
          variant="contained"
          sx={{
            color: "#FFF",
            backgroundColor: "#fa9441",
            "&:hover": {
              bgcolor: "#c05600",
            },
          }}
          onClick={onPreview}
        >
          Preview
        </Button>
      </Stack>
    );
  };

  const columns: GridColDef[] = [
    // { field: "uid", headerName: "UID", flex: 1, sortable: false },
    {
      field: "formTypeName",
      headerName: "Form Type Name",
      minWidth: 320,
      flex: 1,
    },
    { field: "groupName", headerName: "Group Name", minWidth: 320, flex: 1 },
    {
      field: "status",
      headerName: "Status",
      minWidth: 300,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.status.toLowerCase() === "active" ? (
              <Chip label="ACTIVE" color="success" variant="outlined" />
            ) : (
              <Chip label="INACTIVE" color="warning" variant="outlined" />
            )}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 400,
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  //getting rows for datagrid
  const rows = dataFType.map((row: any) => ({
    id: row.uc_frm_type_id,
    uid: row.uc_frm_type_uid,
    formTypeName: row.uc_frm_type_name,
    groupName: row.uc_frm_grp_name,
    status: row.uc_frm_type_status,
  }));

  return (
    <>
      <div style={{ height: "650px", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          // autoHeight
          disableColumnSelector
          // sx={styles.root}
        />
      </div>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={totalRecFType}
      />
    </>
  );
};

export default FormTypesTable;
