import * as yup from "yup";

import {
  validationNumberField,
  validationTextFieldTypeForm,
  validationStringSelect,
} from "./defaultValidations";

export default yup.object({
  // uid: validationTextField("UID", 3, 127),
  typeName: validationTextFieldTypeForm("Type Name", 3, 127),
  formTypeOrder: validationNumberField("Order Form"),
  // typeDescription: validationTextField("Type Description", 3, 127),
  groupName: validationStringSelect("Group Name"),
  // formTypeParent: validationStringSelect("Form Type Parent"),
  status: validationStringSelect("Status"),
});
