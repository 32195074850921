import { Box, Stack, Button, Typography, Grid } from "@mui/material";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { getEndUserPage } from "../../store/feature/endUser";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import DynamicForm from "../../components/molecules/forms/dynamicForm/DynamicForm";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";

const EndUserForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [typeGrid, setTypeGrid] = useState<Array<string>>([]);
  const {
    formParams,
    formTypes, defaultVal, paramIdWithOpt, dictValueId,
    isLoadingEndUser
  } =
    useAppSelector((state: RootState) => state.endUser);
   const {formType} = useAppSelector((state: RootState) => state.user);

  useEffect(() => {
    if(Object.keys(formType).length !== 0){
      dispatch(getEndUserPage(id));
    }
  }, [formType]);

  let paramsLength = Math.ceil(formParams.length / 2)
  let formRight = formParams.slice(0, paramsLength);
  let formLeft = formParams.slice(paramsLength);
  const {
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<any>({
    reValidateMode: "onChange",
  });
  const generateOptions = (e: any) => {
    // console.log('e ha e ha ====> ', e)
    if (e.form_param_parent_param_id !== 0) {
      return e.form_param_option_values?.filter(
        (item: any) =>
          item.parent_val ===
          Number(watch(`${e.form_param_parent_param_id}`)?.split("-")[0])
      );
    }
    // console.log(' e form option value ===>  ', watch(`${e.form_param_parent_param_id}`))
    return e.form_param_option_values || [];
  };
  const handleAddTable = () => {
    // for header
    // const temp = [];
    // for (const key in headerGrid) {
    //   if (Object.prototype.hasOwnProperty.call(headerGrid, key)) {
    //     const element: string = headerGrid[key];
    //     temp.push({ field: key, headerName: element });
    //   }
    // }
    // // console.log('temp here ===> ', temp);
    // setColumns(temp);
    // setRows((prev: any) => {
    //   return [...prev, { id: new Date().toString(), ...valueGrid }];
    // });
    // setValueGrid({});
  };

  const handleChange = (data: any) => {
    // const result = { ...valueGrid };
    // const resultHeader = { ...headerGrid };
    // result[data.id] = data.value;
    // resultHeader[data.id] = data.title;
    // setValueGrid(result);
    // setHeaderGrid(resultHeader);
  };

  console.log("length form params ===> ", formParams.length);
  return (
    <DetailDataSkeleton
    isLoading={isLoadingEndUser}
    isLoadingEvent={false}
    inputCount={6}>
    <Box component="form">
      <Typography variant="h3" fontFamily="Open Sans" marginY="20px">
        {`Preview ${formTypes.form_type_name != undefined ? formTypes.form_type_name : ''}`} 
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {formRight.map((e: any) => (
            <>
              <DynamicForm
                control={control}
                type={e.form_param_field_type}
                name={`${e.form_param_id}`}
                label={e.form_param_title}
                labelValue={""}
                defaultValue={undefined}
                keyVal={e.form_param_id}
                key={e.form_param_id}
                errors={errors}
                setTypeGrid={setTypeGrid}
                options={generateOptions(e)}
                handleChange={handleChange}
                // rows={rows}
                // columns={columns}
                handleAddTable={handleAddTable}
                parentId={`${e.form_param_parent_param_id}`}
                watch={watch}
                helpNotation={e.form_param_help_notation}
              />
            </>
          ))}
        </Grid>
        <Grid item xs={6}>
          {formLeft.map((e: any) => (
            <>
              <DynamicForm
                control={control}
                type={e.form_param_field_type}
                name={`${e.form_param_id}`}
                label={e.form_param_title}
                labelValue={""}
                defaultValue={undefined}
                keyVal={e.form_param_id}
                key={e.form_param_id}
                errors={errors}
                setTypeGrid={setTypeGrid}
                options={generateOptions(e)}
                handleChange={handleChange}
                // rows={rows}
                // columns={columns}
                handleAddTable={handleAddTable}
                parentId={`${e.form_param_parent_param_id}`}
                watch={watch}
                helpNotation={e.form_param_help_notation}
              />
            </>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button
              variant="contained"
              color="gray"
              onClick={() => navigate("/form-type")}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" disabled>
              Submit
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
    </DetailDataSkeleton>
  );
};

export default EndUserForm;
