import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance, instanceEndUser } from "../../service/instance";

interface IEndUser {
  data: any;
  formParams: any;
  defaultVal: Array<any>;
  formTypes: any;
  isLoadingEndUser: boolean;
  isLoadingEventTable: boolean;
  paramIdWithOpt: any;
  dictValueId: any;
}

const initialState: IEndUser = {
    data: [],
    formParams: [],
    defaultVal: [],
    formTypes: [],
    isLoadingEndUser: false,
    isLoadingEventTable: false,
    paramIdWithOpt: [],
    dictValueId: {},
  };


  export const getEndUserPage = createAsyncThunk<any, any, { rejectValue: any }>(
    "endUserPage",
    async (data, { rejectWithValue, getState }) => {
      try {
        // console.log("id here ===> ", data);
        const state: any = getState();
        const uid = state?.user.formType.uc_adm_pge_access_uid;
        console.log('mau pada ngemieeeee ==? ', state?.user.formType.uc_adm_pge_access_uid)
        const resp = await instanceEndUser.get(`/form/${data}`, {
          headers: {
            'page-access-uid': uid,
          },
        });
        let respData  = resp.data.data
        let frmParams = respData.form_params;
        let frmTypes  = respData.form_type
        let tempVal: any = {};
        let withOpt: any = [];
        frmParams.forEach((e: any) => {
            tempVal[e.form_param_id] = e.form_param_default_value;
            if (e.form_param_option_values !== null)
              withOpt.push(e.form_param_id);
          });
        
        console.log('console everyting here ====> ', { respData, frmParams, frmTypes, tempVal, withOpt })
        return { respData, frmParams, frmTypes, tempVal, withOpt }
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );


  // for checking button params and delete 
  export const getBodyTblForCheckPrm = createAsyncThunk<any, any, { rejectValue: any }>(
    "form/body-list",
    async (data, { rejectWithValue, getState }) => {
      try {
        const state: any = getState();
        const uid = state?.user.formType.uc_adm_pge_access_uid;
        const resp = await instance.get(`/form/entries/${data}`, {
          headers: {
            'page-access-uid': uid,
          },
        }
        // {params}
        );
        // console.log('id => ', id, 'params => ', params);
        console.log('=========> body table', resp.data.data)
        return resp.data.data;
      } catch (err) {
        // console.log('error disini ====> ', err)
        throw rejectWithValue(err);
      }
    }
  );

  export const endUserPageSlice = createSlice({
    name: "endUserPage",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
        .addCase(getEndUserPage.pending, (state)=> {
            state.isLoadingEndUser = true
            state.formParams = []
            state.formTypes = []
            state.defaultVal = []
            state.paramIdWithOpt = []
        })
        .addCase(getEndUserPage.fulfilled, (state, action)=> {
          state.isLoadingEndUser = false
            const { frmParams, frmTypes, tempVal, withOpt } = action.payload
            state.formParams = frmParams
            state.formTypes = frmTypes
            state.defaultVal = tempVal
            state.paramIdWithOpt = withOpt
        })
        .addCase(getEndUserPage.rejected, (state)=> {
            state.isLoadingEndUser = false
            state.formParams = []
            state.formTypes = []
            state.defaultVal = []
            state.paramIdWithOpt = []
        })

        .addCase(getBodyTblForCheckPrm.pending, (state) => {
          state.isLoadingEventTable = true;
        })
        .addCase(getBodyTblForCheckPrm.fulfilled, (state, action) => {
          state.isLoadingEventTable = false;
        })
        .addCase(getBodyTblForCheckPrm.rejected, (state) => {
          state.isLoadingEventTable = false;
        });
    },
  })



  export default endUserPageSlice.reducer;