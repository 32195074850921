import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { getFormType, rmvMsgSccsFTy } from "../../store/feature/formType";
import FormTypesSearch from "../../components/molecules/formType/FormTypesSearch";
import FormTypesTable from "../../components/molecules/formType/FormTypesTable";
import { useNavigate } from "react-router-dom";

// icon
import AddIcon from "@mui/icons-material/Add";
import Toast from "../../components/atoms/toast";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";

const FormType = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [open, setOpen] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});
  const { msgSccsFTy, isLoadingType, isLoadingEvent } = useAppSelector(
    (state: RootState) => state.fType
  );
  const { formType } = useAppSelector((state: RootState) => state.user);
  const { isLoadingEventTable } = useAppSelector(
    (state: RootState) => state.endUser
  );

  const handleCloseToast = () => {
    dispatch(rmvMsgSccsFTy());
  };

  useEffect(() => {
    if (Object.keys(formType).length !== 0) {
    dispatch(getFormType(params));
    }
  }, [params, formType]);

  // console.log('data from type page ====> ', useEffect)
  // console.log('params baru', params)

  return (
    <ListDataSkeleton
      isLoading={isLoadingType}
      isLoadingEvent={isLoadingEvent || isLoadingEventTable}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}
        >
          Form Type
        </Typography>

        {formType.uc_adm_pge_acs_create === 1 && (
          <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/form-type/create")}
              sx={{ marginBottom: "18px" }}
            >
              <AddIcon /> Create
            </Button>
          </Box>
        )}
        <FormTypesSearch
          params={params}
          setParams={setParams}
          open={open}
          setOpen={setOpen}
        />
        <FormTypesTable
          // data={dataFType?.data}
          params={params}
          setParams={setParams}
          open={open}
          setOpen={setOpen}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
        <Toast
          severity="success"
          open={msgSccsFTy !== ""}
          handleClose={handleCloseToast}
          message={msgSccsFTy}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default FormType;
