import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useForm } from 'react-hook-form';
import {
  InputForm,
  TextAreaForm,
  SelectFormm,
} from '../../components/molecules/forms/index';
import { useLocation } from 'react-router-dom';
import formGroup from '../../utils/validation/formGroup';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import {
  createFormGroup,
  deleteFormGroup,
  getFormGroupId,
  updateFormGroup,
} from '../../store/feature/formGroup';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import DetailDataSkeleton from '../../components/template/skeleton/DetailDataSkeleton';

const FormGroupField = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;

  const idParam = useParams();
  console.log('id param ===> ', idParam.id);
  const { editDataFGroup, isLoadingGroup, isLoadingEvent } = useAppSelector((root: RootState) => root.fGroup);
  const {
    uc_frm_grp_description,
    uc_frm_grp_function_name,
    uc_frm_grp_uid,
    uc_frm_grp_name,
    uc_frm_grp_status,
  } = editDataFGroup;
  const editFgroup = pathname.includes('edit');
  console.log(' from addcase form group ===> ', editDataFGroup);
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    groupName: string;
    functionMenuName: string;
    groupDescription: string;
    uid: string;
    status: string;
  }>({
    reValidateMode: 'onChange',
    resolver: yupResolver(formGroup),
    defaultValues: {
      groupName: '',
      functionMenuName: '',
      groupDescription: '',
      uid: '',
      status: '',
    },
  });

  const onSubmit = (e: any) => {
    console.log('e here ===> ', e);
    const newTemp = {
      uc_frm_grp_uid: e.uid,
      uc_frm_grp_description: e.groupDescription,
      uc_frm_grp_function_name: e.functionMenuName,
      uc_frm_grp_name: e.groupName,
      uc_frm_grp_status: e.status,
    };
    console.log('update form ===> ', newTemp);
    const newCreateTemp = {
      uc_frm_grp_description: e.groupDescription,
      uc_frm_grp_function_name: e.functionMenuName,
      uc_frm_grp_name: e.groupName,
      uc_frm_grp_status: e.status,
    };
    // JSON.parse(newTemp)
    // let obj: any = JSON.parse(newCreateTemp)
    // console.log('from group page =======> ',typeof )
    if (editFgroup) {
      dispatch(updateFormGroup(newTemp));
      navigate('/form-group');
    } else {
      dispatch(createFormGroup(newCreateTemp));
      navigate('/form-group');
    }
  };
  const optStatusSelect = [
    {
      label: 'ACTIVE',
      value: 'ACTIVE',
    },
    {
      label: 'INACTIVE',
      value: 'INACTIVE',
    },
  ];

  useEffect(() => {
    if (idParam.id !== undefined) {
      dispatch(getFormGroupId(idParam.id));
    }
  }, []);

  useEffect(() => {
    console.log(' edit data group name ===> ', editDataFGroup);
    if (editFgroup) {
      reset({
        groupName: uc_frm_grp_name,
        functionMenuName: uc_frm_grp_function_name,
        groupDescription: uc_frm_grp_description,
        uid: uc_frm_grp_uid,
        status: uc_frm_grp_status?.toUpperCase(),
      });
    } else {
      reset();
    }
  }, [editDataFGroup]);

  const handleDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#808080',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log('delete success haha ===> ', params.id);
        dispatch(deleteFormGroup(idParam.id));
        navigate('/form-group');
      }
    });
  };

  return (
    <DetailDataSkeleton
    isLoading={isLoadingGroup}
    isLoadingEvent={isLoadingEvent}
    inputCount={6}>
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h3" fontFamily="Open Sans" marginY="20px">
        {editFgroup ? 'Update Form Group' : 'Create Form Group'}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {/* {editFgroup && (
            <InputForm
              name="id"
              label="ID"
              errors={errors}
              control={control}
              required
              maxLength={100}
              isLoading={false}
              disabled
            />
          )} */}
          <InputForm
            name="groupName"
            label="Group Name"
            errors={errors}
            control={control}
            required
            placeholder="Type Group Name Here....."
            maxLength={100}
            isLoading={false}
          />
          <InputForm
            name="functionMenuName"
            label="Function Menu Name"
            errors={errors}
            control={control}
            required
            placeholder="Type Function Menu Name Here....."
            maxLength={100}
            isLoading={false}
          />
        </Grid>
        <Grid item xs={6}>
          <TextAreaForm
            name="groupDescription"
            label="Group Remark"
            errors={errors}
            control={control}
            placeholder="Type Group Remark Here....."
            maxLength={100}
            isLoading={false}
            defaultValue={''}
          />
          <SelectFormm
            name="status"
            label="Status"
            defaultValue={1}
            options={optStatusSelect}
            placeholder="None"
            disabled={false}
            isLoading={false}
            control={control}
            errors={errors}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            {editFgroup && (
              <Button variant="contained" color="error" onClick={handleDelete}>
                Delete
              </Button>
            )}
            <Button
              variant="contained"
              color="gray"
              onClick={() => navigate('/form-group')}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              {editFgroup ? 'Update' : 'Submit'}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
    </DetailDataSkeleton>
  );
};

export default FormGroupField;
