import {
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import MuiAppBar from "@mui/material/AppBar";
import { IOpenMainProps } from "../../../interfaces/IUtils";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

// Icons
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { clearStorage } from "../../../utils/storage";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { removeToken } from "../../../store/feature/user";

const drawerWidth = 240;

const Navbar = ({ open, setOpen }: IOpenMainProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const param = useParams();
  const theme = useTheme();
  const settings = ["Profile", "Account", "Dashboard", "Logout"];
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const { formTypes } = useAppSelector((state: RootState) => state.endUser);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [offset, setOffset] = useState(0);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleButton = (setting: any) => {
    if (setting === "Logout") {
      dispatch(removeToken());
      navigate("/login", { replace: true });
      clearStorage();
    }
  };

  const breadcrumb: any = [{ label: "Dashboard", href: "/" }];

  if (pathname === "/form-group") {
    breadcrumb.push({ label: "Form Group", href: "/form-group" });
  } else if (pathname === "/form-group/create") {
    breadcrumb.push({ label: "Form Group", href: "/form-group" });
    breadcrumb.push({ label: "Create Form Group", href: "/form-group/create" });
  } else if (pathname === `/form-group/edit/${param.id}`) {
    breadcrumb.push({ label: "Form Group", href: "/form-group" });
    breadcrumb.push({
      label: "Update Form Group",
      href: `/form-group/edit/${param.id}`,
    });
  } else if (pathname === "/form-type") {
    breadcrumb.push({ label: "Form Type", href: "/form-type" });
  } else if (pathname === "/form-type/create") {
    breadcrumb.push({ label: "Form Type", href: "/form-type" });
    breadcrumb.push({ label: "Create Form Type", href: "/form-type/create" });
  } else if (pathname === `/form-type/edit`) {
    breadcrumb.push({ label: "Form Type", href: "/form-type" });
    breadcrumb.push({
      label: "Update Form Type",
      href: `/form-type/create${param.id}`,
    });
  } else if (pathname === `/params`) {
    breadcrumb.push({ label: "Form Type", href: "/form-type" });
    breadcrumb.push({
      label: "Add Params",
      href: `/params/params-id=${param.id}`,
    });
  } else if (pathname === `/form-type/preview/${param.id}`) {
    breadcrumb.push({ label: "Form Type", href: "/form-type" });
    breadcrumb.push({
      label: `Preview ${
        formTypes.form_type_name != undefined ? formTypes.form_type_name : ""
      }`,
      href: `/form-type/preview/${param.id}`,
    });
  } else if (pathname === "/service-management") {
    breadcrumb.push({
      label: "Service Management",
      href: "/service-management",
    });
  } else if (pathname === "/service-management/create") {
    breadcrumb.push({
      label: "Service Management",
      href: "/service-management",
    });
    breadcrumb.push({
      label: "Create Service Management",
      href: "/service-management/create",
    });
  } else if (pathname === `/service-management/edit`) {
    breadcrumb.push({
      label: "Service Management",
      href: "/service-management",
    });
    breadcrumb.push({
      label: "Update Service Management",
      href: `/service-management/edit/${param.id}`,
    });
  }
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <MuiAppBar
      position="fixed"
      sx={{
        display: "flex",
        background: "transparent",
        // background: 'yellow',
        boxShadow: "none",
        // backgroundColor: '#B3c100',
        alignItems: "space-between",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }}
    >
      <Toolbar sx={{ borderRadius: "none", paddingLeft: 0 }} variant="dense">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: "33px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        {/* <Box sx={{ flex: 1 }}></Box> */}
        <Box
          sx={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          {offset <= 30 && (
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{
                backgroundColor: "transparent",
                flex: 1,
                marginTop: "12px",
                cursor: "pointer",
              }}
            >
              {breadcrumb.map((item: any, index: number) => {
                return index != breadcrumb.length - 1 ? (
                  <Link
                    key={item.label}
                    // href={item.href}
                    onClick={() => navigate(item.href)}
                    underline="hover"
                  >
                    {item.label}
                  </Link>
                ) : (
                  <Typography key={item.label} color="textPrimary">
                    {item.label}
                  </Typography>
                );
              })}
            </Breadcrumbs>
          )}
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt="Remy Sharp"
                src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                {/* <Typography textAlign="center">{setting}</Typography> */}
                <Button onClick={() => handleButton(setting)} sx={{width: '100%', justifyContent: "flex-start", textTransform: "none" , paddingX: '12px'}}>{setting}</Button>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer */}
        {/* </Typography> */}
        {/* <Navbar /> */}
      </Toolbar>
    </MuiAppBar>
  );
};

export default Navbar;
