import ServManag from "../pages/serviceManagement/ServManag";
import SMField from "../pages/serviceManagement/SMField";
import IRoutes from "../interfaces/IRoutes";

// const FeedPage = React.lazy(() => import('../pages/feed/Feed'));

const SrvMngRoutes: Array<IRoutes> = [
  {
    element: <ServManag />,
    path: "/service-management",
  },
  {
    element: <SMField />,
    path: "/service-management/create",
  },
  {
    element: <SMField />,
    path: "/service-management/edit",
  }
];

const SrvMngRoutesErr: Array<IRoutes> = [
  {
    element: <ServManag />,
    path: "/service-management",
  },
  {
    element: <SMField />,
    path: "/service-management/create",
  },
  {
    element: <SMField />,
    path: "/service-management/edit",
  }
];

export { SrvMngRoutes, SrvMngRoutesErr };
