import React from 'react';
import {
  FormControl,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  useTheme,
  Skeleton,
} from '@mui/material';

import { Controller } from 'react-hook-form';
import FormLabel from '../../../atoms/formLabel/index';
import globalStyles from '../../../../utils/styles';

interface Iprops {
  sxFC?: any;
  sxFL?: any;
  name: string;
  label: string;
  errors?: any;
  control?: any;
  options: any[];
  defaultValue: string | number;
  disabled?: boolean;
  required?: boolean;
  vertical?: boolean;
  isLoading?: boolean;
  hideRadioLabel?: boolean;
  formSx?: any;
}

export default function RadioForm(props: Iprops) {
  const {
    sxFC,
    sxFL,
    name,
    label,
    control,
    defaultValue,
    errors,
    options,
    disabled,
    required = false,
    vertical = false,
    isLoading = false,
    hideRadioLabel = false,
    formSx,
  } = props;

  const { palette } = useTheme();
  return (
    <FormControl
      sx={{ flex: 1, display: 'flex', mb: '1rem', ...sxFC }}
      variant="standard"
    >
      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
        {label !== '' && (
          <FormLabel
            htmlFor={name}
            sx={{
              color: `${palette.neutral.dark} !important`,
              width: '10rem',
              mb: '0.45rem',
              ...sxFL,
            }}
          >
            {label}{' '}
            {!required && !disabled && (
              <span
                style={{
                  marginLeft: '4px',
                  color: palette.neutral.light,
                  fontWeight: 400,
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                  fontStyle: 'italic',
                }}
              >
                (optional)
              </span>
            )}
            {required && label !== undefined && !disabled && (
             <span style={{color: '#ef4545'}}> *</span>
          )}
          </FormLabel>
        )}
      </Box>
      <Controller
        defaultValue={defaultValue}
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup
            {...field}
            sx={{
              flexDirection: vertical ? 'column' : 'row',
            }}
          >
            {!isLoading &&
              options.map((e: any) => (
                <FormControlLabel
                  disabled={disabled}
                  key={e.value}
                  value={e.value}
                  sx={{
                    ...globalStyles(palette),
                    '&:hover': {
                      cursor: disabled ? 'no-drop' : 'default',
                    },
                    '& .MuiFormControlLabel-label': {
                      fontSize: '1rem',
                    },
                    height: '1.5rem',
                    mb: '0.5rem',
                    ...formSx,
                  }}
                  control={<Radio size="medium" />}
                  label={hideRadioLabel ? '' : e.label}
                />
              ))}

            {isLoading &&
              options.map((e: any) => (
                <Box
                  key={e.label}
                  sx={{
                    display: 'flex',
                    marginBottom: '12px',
                    marginRight: '16px',
                  }}
                >
                  <Skeleton
                    style={{
                      height: '20px',
                      width: '20px',
                      borderRadius: '50%',
                      marginRight: '12px',
                    }}
                  />
                  <Skeleton
                    style={{
                      height: '20px',
                      width: '80px',
                      borderRadius: '4px',
                    }}
                  />
                </Box>
              ))}
          </RadioGroup>
        )}
      />
      <FormHelperText sx={{ fontSize: '0.75rem', color: palette.error.main }}>
        {errors[name] && errors[name].message}
      </FormHelperText>
    </FormControl>
  );
}
