import FormTypes from '../pages/formType/FormTypes';
import FormTypeField from '../pages/formType/FormTypeField';
import IRoutes from "../interfaces/IRoutes";
import EndUserForm from '../pages/endUser/EndUserForm';

// const FeedPage = React.lazy(() => import('../pages/feed/Feed'));

import NotFound from '../pages/notFound/NotFound';

const FormTypesRoutes: Array<IRoutes> = [
  {
    element: <FormTypes />,
    path: '/form-type',
  },
  {
    element: <FormTypeField />,
    path: "/form-type/create",
  },
  {
    element: <FormTypeField />,
    path: "/form-type/edit",
  },
  {
    element: <EndUserForm />,
    path: "/form-type/preview/:id",
  }
];

const FormTypesRoutesErr: Array<IRoutes> = [
  {
    element: <FormTypes />,
    path: '/form-type',
  },
  {
    element: <FormTypeField />,
    path: "/form-type/create",
  },
  {
    element: <FormTypeField />,
    path: "/form-type/edit",
  },
  {
    element: <EndUserForm />,
    path: "/form-type/preview/:id",
  }
];

export { FormTypesRoutes, FormTypesRoutesErr };
