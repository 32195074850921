import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes";
import { RootState, useAppSelector } from "./store";
import { FormGRoupRoutes, FormGRoupRoutesErr } from "./routes/formGroup";
import { FormTypesRoutes, FormTypesRoutesErr } from "./routes/formType";
import { SrvMngRoutes, SrvMngRoutesErr } from "./routes/serviceMng";

function App() {
  const { formGroup, formType, srvMng } = useAppSelector(
    (state: RootState) => state.user
  );

  const filteredRoutes = routes[0].children?.filter(
    (item: any) =>
      !FormGRoupRoutes.includes(item) &&
      !FormGRoupRoutesErr.includes(item) &&
      !FormTypesRoutes.includes(item) &&
      !FormTypesRoutesErr.includes(item) &&
      !SrvMngRoutes.includes(item) &&
      !SrvMngRoutesErr.includes(item)
  );

  if(formGroup != undefined) {
    filteredRoutes?.push(...FormGRoupRoutes)
  } else {
    filteredRoutes?.push(...FormGRoupRoutesErr)
  }

  if(formType != undefined) {
    filteredRoutes?.push(...FormTypesRoutes)
  } else {
    filteredRoutes?.push(...FormTypesRoutesErr)
  }

  if(srvMng != undefined) {
    filteredRoutes?.push(...SrvMngRoutes)
  } else {
    filteredRoutes?.push(...SrvMngRoutesErr)
  }

  routes[0].children = filteredRoutes

  const router = createBrowserRouter(routes);
  return <RouterProvider fallbackElement="..loading" router={router} />;
}

export default App;
