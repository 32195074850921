import { Box, FormControlLabel, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { SelectFormm, CheckboxForm } from "../../components/molecules/forms";
import { RootState, useAppSelector, useAppDispatch } from "../../store";
import { getParamsByIdForParamForm } from "../../store/feature/params";
import { getFormType } from "../../store/feature/formType";
import OptionValueDnd from "./OptionValueDnd";

const HiddenParamsField = ({
  control,
  errors,
  defaultValues,
  setDefaultValues,
  watch,
  setValue,
  setOpen,
  open,
}: any) => {
  const dispatch = useAppDispatch();
  const { formTypeSelectId } = useAppSelector((state: RootState) => state.fType);
  const { optParentParamFormPage } = useAppSelector(
    (state: RootState) => state.param
  );
  // console.log('form type select ===> ', formTypeSelect)

  const handleFuncMenu = (e: any) => {
    dispatch(getParamsByIdForParamForm(e.value));
  };

  useEffect(() => {
    if (open) {
      dispatch(getFormType({pagesize: 100}));
    }
  }, [open]);

  const optRefer = [
    {
      label: "Automated Generation",
      value: "AUTOMATED_GENERATION",
    },
    {
      label: "Pay Transaction Input Validation",
      value: "PAY_TRANSACTION_INPUT_VALIDATION",
    },
  ];

  const handleChangeRefer = (e: any) => {
    console.log("handlechangerefer ====> ", e);
  };
  const handleCheckBox = () => {
    setOpen((prev: any) => !prev);
  };

  const handleForParam = (e: any) => {
    console.log("chat gpt ====> ", e.value);
  };

  return (
    <Box>
      <>
        <FormControlLabel
          control={<Checkbox defaultChecked checked={open} />}
          label="Refer to other data?"
          onClick={handleCheckBox}
          defaultChecked
        />
      </>
      {!open ? (
        <OptionValueDnd
          control={control}
          errors={errors}
          defaultValues={defaultValues}
          setDefaultValues={setDefaultValues}
          watch={watch}
          setValue={setValue}
        />
      ) : (
        <>
          <SelectFormm
            name="funcMenu"
            label="Form Menu"
            defaultValue={1}
            options={formTypeSelectId}
            placeholder="None"
            disabled={false}
            isLoading={false}
            control={control}
            errors={errors}
            onChangeForm={handleFuncMenu}
            isSearch
          />
          <SelectFormm
            name="param"
            label="Param"
            defaultValue={1}
            options={optParentParamFormPage}
            placeholder="None"
            disabled={false}
            isLoading={false}
            control={control}
            errors={errors}
            onChangeForm={handleForParam}
          />
        </>
      )}
    </Box>
  );
};

export default HiddenParamsField;
