import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  TextAreaForm,
  SelectFormm,
} from "../../components/molecules/forms/index";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import formType from "../../utils/validation/formType";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { deleteFormGroup, getFormGroup } from "../../store/feature/formGroup";
import {
  getFormType,
  updateFormType,
  createFormType,
  getFormTypeId,
} from "../../store/feature/formType";
import { useEffect } from "react";
import Swal from "sweetalert2";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";

const FormTypeField = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [param] = useSearchParams();
  const idFType = param.get("param-uid");
  const { editDataFType, formTypeSelect, isLoadingType, isLoadingEvent } =
    useAppSelector((root: RootState) => root.fType);
  const { formGroupSelect, isLoadingGroup } = useAppSelector(
    (state: RootState) => state.fGroup
  );
  const { formType: formTypeUser } = useAppSelector(
    (state: RootState) => state.user
  );
  const {
    uc_frm_grp_id,
    uc_frm_type_description,
    uc_frm_type_order,
    // uc_frm_type_id,
    uc_frm_type_name,
    uc_frm_type_parent,
    uc_frm_type_status,
    uc_frm_type_uid,
    // uc_frm_type_version,
  } = editDataFType;

  const editFType = pathname.includes("edit");
  const {
    control,
    // watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    id: number;
    uid: string;
    typeName: string;
    typeDescription: string;
    groupName: string;
    formTypeParent: string;
    formTypeOrder: string;
    status: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(formType),
    defaultValues: {
      id: 0,
      uid: "",
      typeName: "",
      typeDescription: "",
      groupName: "",
      formTypeParent: "",
      formTypeOrder: "",
      status: "",
    },
  });

  const onSubmit = (e: any) => {
    const newTemp = {
      uc_frm_type_id: e.id,
      uc_frm_type_uid: e.uid,
      uc_frm_type_name: e.typeName,
      uc_frm_type_description: e.typeDescription,
      uc_frm_grp_id: Number(e.groupName),
      uc_frm_type_parent: Number(e.formTypeParent),
      uc_frm_type_order: Number(e.formTypeOrder),
      uc_frm_type_status: e.status,
    };
    if (editFType) {
      dispatch(updateFormType(newTemp));
      navigate("/form-type");
    } else {
      dispatch(createFormType(newTemp));
      navigate("/form-type");
    }
  };

  const optStatusSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];
  useEffect(() => {
    if (Object.keys(formTypeUser).length !== 0) {
      if (editFType) {
        dispatch(getFormTypeId(idFType));
      }
      dispatch(getFormGroup({ pagesize: 100 }));
      dispatch(getFormType({ pagesize: 100 }));
    }
  }, [formTypeUser]);

  useEffect(() => {
    if (editFType) {
      reset({
        id: uc_frm_grp_id,
        uid: uc_frm_type_uid,
        typeName: uc_frm_type_name,
        typeDescription: uc_frm_type_description,
        groupName: uc_frm_grp_id,
        formTypeParent: uc_frm_type_parent,
        formTypeOrder: uc_frm_type_order,
        status: uc_frm_type_status?.toUpperCase(),
      });
    } else {
      reset();
    }
  }, [editDataFType]);

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes, delete it!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log('delete success haha ===> ', params.id);
        dispatch(deleteFormGroup(idFType));
        navigate("/form-type");
      }
    });
  };

  return (
    <DetailDataSkeleton
      isLoading={isLoadingGroup || isLoadingType}
      isLoadingEvent={isLoadingEvent}
      inputCount={6}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h3" fontFamily="Open Sans" marginY="20px">
          {editFType ? "Update Form Type" : "Create From Type"}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputForm
              name="typeName"
              label="Type Name"
              errors={errors}
              control={control}
              required
              placeholder="Type Name Here....."
              maxLength={100}
              isLoading={false}
            />
            <TextAreaForm
              name="typeDescription"
              label="Type Description"
              errors={errors}
              control={control}
              required
              placeholder="Type Description Here....."
              maxLength={100}
              isLoading={false}
              defaultValue={""}
            />
            <SelectFormm
              name="groupName"
              label="Group Name"
              defaultValue={1}
              options={formGroupSelect}
              placeholder="None"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <SelectFormm
              name="formTypeParent"
              label="From Type Parent"
              defaultValue={1}
              options={formTypeSelect}
              placeholder="None"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
            />
            <InputForm
              name="formTypeOrder"
              label="Order Form"
              errors={errors}
              control={control}
              required
              placeholder="Type Order Here....."
              maxLength={100}
              isLoading={false}
              type="number"
            />
            <SelectFormm
              name="status"
              label="Status"
              defaultValue={1}
              options={optStatusSelect}
              placeholder="None"
              disabled={false}
              isLoading={false}
              control={control}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              {editFType && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              )}
              <Button
                variant="contained"
                color="gray"
                onClick={() => navigate("/form-type")}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                {editFType ? "Update" : "Submit"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </DetailDataSkeleton>
  );
};

export default FormTypeField;
