
import IRoutes from '../interfaces/IRoutes';
import Dashboard from '../pages/dashboard/Dashboard';
import NotFound from '../pages/notFound/NotFound';
import MainContainer from '../components/template/mainContainer/MainContainer';
import Profile from '../pages/profile/Profile';
import Login from '../pages/login/Login';

// uno CA cms
// import FormType from './formType';
// import formGroup from './formGroup';
import Params from '../pages/params/Params';
// import ServiceMng from './serviceMng'


const routes: IRoutes[] = [
  {
    element: <MainContainer />,
    path: '/',
    errorElement: <NotFound />,
    children: [
      {
        element: <Dashboard />,
        index: true,
      },
      {
        element: <Login />,
        path: '/login',
      },
      {
        element: <Profile />,
        path: '/profile',
      },
      // ...formGroup,
      // ...FormType,
      // ...ServiceMng,
      {
        element: <Params/>,
        path: '/params'
      }
    ],
  },
];

export default routes;
