import { styled, TextField, Box } from '@mui/material';

export const StyledInput = styled(TextField)(({ theme }) => ({
  'label + &': {
    marginTop: '0.5rem',
  },
  '& input.Mui-disabled': {
    WebkitTextFillColor: theme.palette.neutral.main,
    color: theme.palette.neutral.main,
  },
  '& .MuiInputBase-input': {
    '&::placeholder': {
      textTransform: 'none',
    },
  },
  '& .Mui-disabled: not(.Mui-error)': {
    fontWeight: 400,
    fontSize: '1rem',
    backgroundColor: theme.palette.gray.light,
    WebkitTextFillColor: theme.palette.neutral.main,
    borderRadius: 4,
    '& fieldset': {
      borderColor: '#FFF !important',
    },
    '&:hover': {
      cursor: 'no-drop',
    },
  },
  '& .MuiTextField-root': {
    display: 'flex',
    flex: 1,
    FontFamily: 'Open Sans',
    borderRadius: 4,
    position: 'relative',
    fontSize: '1rem',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: `1px solid ${theme.palette.gray.main}`,
    },
    padding: 0,
  },
  // remove scroll for chrome, safari, opera
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  // remove scroll for mozilla
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
  // remove eye from input password in edge
  'input::-ms-reveal,input::-ms-clear': {
    display: 'none',
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  'label + &': {
    marginTop: '6.5px',
  },
}));
