import { Stack, IconButton, Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import {
  getParams,
  updateParamsId,
  getParamsId,
  getParamsById,
} from "../../store/feature/params";
import { useParams, useSearchParams } from "react-router-dom";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import "./param.css";
import { useTheme } from "@mui/material/styles";

//icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const ParamsTable = ({ dataTableParam, setDataTableParam, button }: any) => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const [param] = useSearchParams();
  const id = param.get("param-id");
  const { dataParamPage, paramIdCreateP } = useAppSelector(
    (state: RootState) => state.param
  );
  const { formType } = useAppSelector((state: RootState) => state.user);
  const { creOptVal } = useAppSelector((state: RootState) => state.optVal);
  const { dataUpdateFormParam } = useAppSelector(
    (state: RootState) => state.param
  );
  useEffect(() => {
    if (Object.keys(formType).length !== 0) {
      dispatch(getParams(""));
      dispatch(getParamsById(id));
    }
  }, [creOptVal, paramIdCreateP, dataUpdateFormParam, formType]);

  const styleCell = {
    width: 70,
    maxWidth: 70,
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderStyle: "border-box",
  };

  const handleDelete = (row: any) => {
    let newDelete = {
      uc_frm_param_status: "deleted",
      uc_frm_param_id: row.uc_frm_param_id,
      uc_frm_type_id: row.uc_frm_type_id,
    };
    console.log("here new delete handle ===> ", newDelete);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#808080",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updateParamsId(newDelete));
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: palette.primary.main,
          text: "Your file has been deleted.",
          didOpen: () => Swal.getConfirmButton()?.focus(),
        });
      }
    });
  };

  const customCellRender = (params: any) => {
    // Button for Edit
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      // navigate(`/form-type/edit/${params.id}`);
      dispatch(getParamsId(params.id));
      console.log("params.id ===> ", params.id);
      // console.log('here params id ====> ', params.row)
    };
    // Button for Delete
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      // checkForDeleteParams(params.id);
      handleDelete({
        uc_frm_param_id: params.id,
        uc_frm_type_id: params.row.typeId,
      });
    };

    return (
      <Stack direction="row">
        <IconButton onClick={onEdit} sx={{ color: "blue" }} disabled={button}>
          <EditIcon />
        </IconButton>
        <IconButton sx={{ color: "red" }} onClick={onDelete} disabled={button}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    );
  };
  // interface CustomGridColDef extends GridColDef {
  //   wrap?: 'nowrap' | 'normal' | 'break-spaces' | 'pre-line';
  // }

  // interface CustomGridColDef extends GridColDef {
  //   customRenderCell?: (params: GridCellParams) => JSX.Element;
  // }

  const columns: GridColDef[] = [
    // { field: "uid", headerName: "UID", flex: 1, sortable: false },
    // { field: "uid", headerName: "UID", flex: 1 },
    { field: "systemName", headerName: "System Name", width: 148 },
    {
      field: "frontendTitle",
      headerName: "Frontend Title",
      width: 150,
      cellClassName: "no-wrap",
    },
    { field: "helpNotation", headerName: "Help Notation", width: 148 },
    { field: "orderPosition", headerName: "System Name", width: 148 },
    { field: "isMandatory", headerName: "Is Mandatory", width: 148 },
    { field: "fieldType", headerName: "Field Type", width: 148 },
    { field: "optionValue", headerName: "Option Value", width: 148 },
    { field: "parentParam", headerName: "Parent Param", width: 148 },
    {
      field: "referToOtherData",
      headerName: "Refer To Other Data",
      width: 148,
    },
    { field: "defaultValue", headerName: "Default Value", width: 148 },
    { field: "status", headerName: "Status", width: 148 },
    {
      field: "action",
      headerName: "Action",
      width: 148,
      renderCell: customCellRender,
    },
  ];

  //
  const rows = dataParamPage.map((row: any) => ({
    systemName: row.uc_frm_param_name,
    frontendTitle: row.uc_frm_param_title,
    helpNotation: row.uc_frm_param_help_notation,
    orderPosition: row.uc_frm_param_order_position,
    isMandatory: row.uc_frm_param_is_mandatory,
    fieldType: row.uc_frm_param_field_type,
    optionValue: row.uc_frm_type_id,
    parentParam: row.uc_frm_param_parent,
    referToOtherData: row.uc_frm_param_ref_other_data,
    defaultValue: row.uc_frm_param_default_value,
    status: row.uc_frm_param_status,
    id: row.uc_frm_param_uid,
    typeId: row.uc_frm_type_id,
  }));

  // console.log("button status ===> ", button);

  return (
    <>
      <div style={{ height: "650px", width: "100%", marginTop: "20px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          // autoHeight
          disableColumnSelector
        />
      </div>
    </>
  );
};

export default ParamsTable;
