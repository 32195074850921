import axios from "axios";
import { clearStorage, getToken } from "../utils/storage";
import { removeToken } from "../store/feature/user";
import Swal from "sweetalert2";

const token = getToken();

export const setHeaderRequest = (res: any) => {
  const token = getToken();
  // console.log('token from interceptor', token)
  if (token) {
    // console.log('token from if condition ==> ', token)
    res.headers["Authorization"] = `Bearer ${token}`;
  }
  return res;
};

export const setError = (err: any) => {
  if (err.response) {
    console.log('response ===>  ', err?.response.data.message === 'Invalid or expired token')
    if (err.response.status === 401 && err?.response.data.message === 'Invalid or expired token' ) {
      removeToken();
      clearStorage();
      window.location.href = "/login";
      console.log('hahah hueueuush ===> ', err?.response.data.message)
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err?.response.data.message,
    })
  }
}
  return Promise.reject(err);
};

export const setHeaderResponse = (res: any) => {
  return res;
};


// console.log('token', token)
export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  timeout: 2500,
  // headers: {'x-access-token': token}
});
instance.interceptors.request.use(setHeaderRequest, setError);
instance.interceptors.response.use(setHeaderResponse, setError);

export const instanceEndUser = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_END_USER,
  timeout: 2500,
  // headers: {'x-access-token': token}
});

instanceEndUser.interceptors.request.use(setHeaderRequest, setError);
instanceEndUser.interceptors.response.use(setHeaderResponse, setError);

export const instanceAdmin = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_ADMIN,
  timeout: 2500,
  // headers: {'x-access-token': token}
  // headers: { Authorization: `Bearer ${getToken()}` }
});

instanceAdmin.interceptors.request.use(setHeaderRequest, setError);
instanceAdmin.interceptors.response.use(setHeaderResponse, setError);
