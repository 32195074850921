import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../service/instance";
import Swal from "sweetalert2";

interface IOptionValue {
  isLoadingOptVal: boolean;
  optParamVal: any;
  optParamValHide: Array<object>;
  creOptVal: string;
}

const initialState: IOptionValue = {
  isLoadingOptVal: false,
  optParamVal: [],
  optParamValHide: [],
  creOptVal: "",
};

// get option value for Page Params

export const getOptValById = createAsyncThunk<any, any, { rejectValue: any }>(
  "params/getOptValById",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
    const uid = state?.user.formType.uc_adm_pge_access_uid;
      const resp: any = await instance.get(
        `/form-option-value-by-param-id/${data}`, {
          headers: {
            'page-access-uid': uid,
          },
        }
      );
      // console.log('paramssssss ======> ', data);
      return resp.data.data.map((e: any) => ({
        label: e.uc_frm_opt_val_name,
        value: e.uc_frm_opt_val_id,
      }));
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

//  get option value for dropdown

export const getOptValByParamId = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("params/getOptValByParamId", async (data, { rejectWithValue, getState }) => {
  // console.log('param id from opt val ====> ', data)
  try {
    const state: any = getState();
    const uid = state?.user.formType.uc_adm_pge_access_uid;
    const resp: any = await instance.get(
      `/form-option-value-by-param-id/${data}`, {
        headers: {
          'page-access-uid': uid,
        },
      }
    );
    // console.log('data option val reducer ====> ', resp.data)
    if (resp.data.status_code === 201) {
      Swal.fire({
        icon: "success",
        title: "Success create form Option Value",
        showConfirmButton: false,
        timer: 2500,
      });
    }
    return resp.data.data.map((e: any) => ({
      label: e.uc_frm_opt_val_name,
      value: JSON.stringify(e.uc_frm_opt_val_id),
    }));
  } catch (err) {
    throw rejectWithValue(err);
  }
});

// form-option-value

export const createOptValue = createAsyncThunk<any, any, { rejectValue: any }>(
  "optValue/create",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.formType.uc_adm_pge_access_uid;
      const resp = await instance.post("/form-option-value", data, {
        headers: {
          'page-access-uid': uid,
        }
      });
      console.log("form Type create ====> ", data);
      console.log("form Type create  response ====> ", resp.data);
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const deleteOptValue = createAsyncThunk<any, any, { rejectValue: any }>(
  "optValue/delete",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.formType.uc_adm_pge_access_uid;
      const resp = await instance.delete("/form-option-value", { data });
      console.log("response from deleteOptvalue ===> ", resp.data);
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const optValSlice = createSlice({
  name: "optVal",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOptValById.pending, (state) => {
        state.isLoadingOptVal = false;
      })
      .addCase(getOptValById.fulfilled, (state, action) => {
        // let optValById = action.payload.data
        // console.log('get opt val id =======> ', action.payload)
        state.optParamVal = action.payload;
        // optValById?.map((e: any)=> ({label: e.uc_frm_opt_val_name, value: e.uc_frm_opt_val_id}))
      })
      .addCase(getOptValById.rejected, (state) => {
        state.isLoadingOptVal = false;
      })
      .addCase(getOptValByParamId.pending, (state) => {
        state.isLoadingOptVal = false;
      })
      .addCase(getOptValByParamId.fulfilled, (state, action) => {
        // let optValByIdHide = action.payload.data
        state.optParamValHide = action.payload;
        // optValByIdHide.map((e: any)=> ({label: e.uc_frm_opt_val_name, value: JSON.stringify(e.uc_frm_opt_val_id)}))
      })
      .addCase(getOptValByParamId.rejected, (state) => {
        state.isLoadingOptVal = false;
      })
      .addCase(createOptValue.fulfilled, (state, action) => {
        state.creOptVal = action.payload.status_code;
      });
  },
});

export const {} = optValSlice.actions;
export default optValSlice.reducer;
