import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { instance } from "../../service/instance";
import { setPageKey } from "./user";

interface IServMng {
  isLoadingServMng: boolean;
  isLoadingEvent: boolean;
  dataServMng: any;
  optionServMng: any;
  optDefByFrmTypeId: any;
  optDefByFrmTypeIdOther: any;
  totalRecServMng: number;
  msgSccsServMng: string;
  servDefUpdate: any;
  servDefParams: any;
}

const initialState: IServMng = {
  isLoadingServMng: false,
  isLoadingEvent: false,
  dataServMng: [],
  optionServMng: [],
  optDefByFrmTypeId: [],
  optDefByFrmTypeIdOther: [],
  totalRecServMng: 0,
  msgSccsServMng: "",
  servDefUpdate: [],
  servDefParams: [],
};

export const getServMngTable = createAsyncThunk<any, any, { rejectValue: any }>(
  "servMng/list",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.srvMng.uc_adm_pge_access_uid
      const resp: any = await instance.get("/service/definition/all", {
        params: data,
        headers: {
          'page-access-uid': uid
        }
      });
      let servMngDataList = resp.data.data;
      let totalRecord = servMngDataList.total_records;
      let optionServMng = servMngDataList.data !== null ?  servMngDataList.data.map((e: any) => ({
        label: e.service_definition_name,
        value: e.service_definition_Id,
      })) : [] ;
      return { servMngDataList, totalRecord, optionServMng };
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const getServMngDefByFrmTypeId = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("servMng/definitionFormType", async (id, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
    const uid = state?.user.srvMng.uc_adm_pge_access_uid
    const resp: any = await instance.get(`service/definition/formtype/${id}`, {
      headers: {
        'page-access-uid': uid
      }
    });
    let data = resp.data.data;
    let option = data.map((e: any) => ({
      label: e.service_definition_name,
      value: e.service_definition_Id,
    }));
    return option;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const getServMngDefByDefntId = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("servMng/definitionByDefinitionId", async (id, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
      const uid = state?.user.srvMng.uc_adm_pge_access_uid
    const resp: any = await instance.get(`service/definition/${id}`, {
      headers: {
        'page-access-uid': uid
      }
    });
    let data = resp.data.data;
    let dataParams = data.service_definition_parameters?.map((e: any, index: any) => ({
      parameterService: e.service_parameter_id,
      squence: e.service_parameter_sequence,
      dataType: e.service_parameter_datatype,
      joinedForm: e.form_param_id,
      joinedParams: e.form_param_id_join,
      id: index,
    }));
    return {data, dataParams};
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const getServMngDefByFrmTypeIdOther = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("servMng/definitionFormTypeOther", async (id, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
      const uid = state?.user.srvMng.uc_adm_pge_access_uid
    const resp: any = await instance.get(`service/definition/formtype/${id}`, {
      headers: {
        'page-access-uid': uid
      }
    });
    let data = resp.data.data;
    let option = data.map((e: any) => ({
      label: e.service_definition_name,
      value: e.service_definition_Id,
    }));
    return option;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const createServMng = createAsyncThunk<any, any, { rejectValue: any }>(
  "servMng/create",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.srvMng.uc_adm_pge_access_uid
      const resp: any = await instance.post("/service/definition", data, {
        headers: {
          'page-access-uid': uid
        }
      });
      let succesMsg = resp.data.msg;
      Swal.fire({
        icon: "success",
        title: succesMsg,
        showConfirmButton: false,
        timer: 2500,
      });
      return succesMsg;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const updateServMng = createAsyncThunk<any, any, { rejectValue: any }>(
  "servMng/update",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.srvMng.uc_adm_pge_access_uid
      const resp: any = await instance.put(`/service/definition/${data.service_definition_uid}`, data, {
        headers: {
          'page-access-uid': uid
        }
      });
      let succesMsg = resp.data.msg;
      Swal.fire({
        icon: "success",
        title: succesMsg,
        showConfirmButton: false,
        timer: 2500,
      });
      return succesMsg;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const deleteServMng = createAsyncThunk<any, any, { rejectValue: any }>(
  "servMng/delete",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.srvMng.uc_adm_pge_access_uid
      const resp = await instance.delete(`/service/definition/${data}`, {
        headers: {
          'page-access-uid': uid
        }
      });
      let succesMsg = resp.data.msg;
      dispatch(getServMngTable({pagesize: 10}));
      dispatch(setPageKey('1'))
      Swal.fire({
        icon: "success",
        title: succesMsg,
        showConfirmButton: false,
        timer: 2500,
      });
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const servMngSlice = createSlice({
  name: "servMng",
  initialState,
  reducers: {
    rmvMsgSccsServMng: (state) => {
      state.msgSccsServMng = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getServMngTable.pending, (state) => {
        state.isLoadingServMng = true;
      })
      .addCase(getServMngTable.fulfilled, (state, action) => {
        const { servMngDataList, totalRecord, optionServMng } = action.payload;
        state.isLoadingServMng = false;
        state.dataServMng =
          servMngDataList.data !== null ? servMngDataList.data : [];
        state.totalRecServMng = totalRecord;
        state.optionServMng = optionServMng;
      })
      .addCase(getServMngTable.rejected, (state) => {
        state.isLoadingServMng = false;
      })
      .addCase(getServMngDefByDefntId.pending, (state) => {
        state.isLoadingServMng = true;
      })
      .addCase(getServMngDefByDefntId.fulfilled, (state, action) => {
        state.isLoadingServMng = false;
        state.servDefUpdate = action.payload.data;
        state.servDefParams = action.payload.dataParams !== undefined ? action.payload.dataParams: [];
      })
      .addCase(getServMngDefByDefntId.rejected, (state) => {
        state.isLoadingServMng = false;
      })
      .addCase(getServMngDefByFrmTypeId.fulfilled, (state, action) => {
        state.optDefByFrmTypeId = action.payload;
      })
      .addCase(getServMngDefByFrmTypeIdOther.fulfilled, (state, action) => {
        state.optDefByFrmTypeIdOther = action.payload;
      })

      .addCase(createServMng.pending, (state, action) => {
        state.isLoadingEvent = true;
      })
      .addCase(createServMng.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
        state.msgSccsServMng = action.payload;
      })
      .addCase(createServMng.rejected, (state, action) => {
        state.isLoadingEvent = false;
      })

      .addCase(updateServMng.pending, (state, action) => {
        state.isLoadingEvent = true;
      })
      .addCase(updateServMng.fulfilled, (state, action) => {
        state.isLoadingEvent = false;
      })
      .addCase(updateServMng.rejected, (state, action) => {
        state.isLoadingEvent = false;
      })
  },
});

export const { rmvMsgSccsServMng } = servMngSlice.actions;
export default servMngSlice.reducer;
