import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import InputForm from "../forms/inputForm";
import DatePickerForm from "../forms/datePickerForm";
import * as yup from "yup";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { useTheme } from '@mui/material/styles';
import { useAppDispatch } from "../../../store";
import { setPageKey } from "../../../store/feature/user";

const schema = yup.object({
  title: yup.string().required(),
});

const ServiceMngSearch = ({ params, setParams }: any) => {
  const dispatch = useAppDispatch()
  const {palette} = useTheme()
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [endpointUrl, setEndpointUrl] = useState("");
  const [createdAtStart, setCreatedAtStart] = useState("");
  const [createdAtEnd, setCreatedAtEnd] = useState("");
  const [updatedAtStart, setUpdatedAtStart] = useState("");
  const [updatedAtEnd, setUpdatedAtEnd] = useState("");

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    serviceName: string;
    endpointUrl: string;
    createdAtStart: string;
    createdAtEnd: string;
    updatedAtStart: string;
    updatedAtEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      serviceName: "",
      endpointUrl: "",
      createdAtStart: "",
      createdAtEnd: "",
      updatedAtStart: "",
      updatedAtEnd: "",
    },
  });

  const handleServiceName = (e: any) => {
    setServiceName(e);
  };

  const handleEndpointUrl = (e: any) => {
    setEndpointUrl(e);
  };

  const handleCreatedAtStart = (e: any) => {
    let NewDate: any
    if (e !== null) {
        NewDate = dayjs(e).format("YYYY-MM-DD");
        if(dayjs(e).isAfter(dayjs(createdAtEnd))) {
          setAllCreatedDate(NewDate);
        } else {
          setCreatedAtStart(NewDate);
        }
    } else {
        NewDate = ''
        setAllCreatedDate(NewDate);
    }
  };
  const handleCreatedAtEnd = (e: any) => {
    let NewDate: any
    if (e !== null) {
        NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
        NewDate = ''
    }
    if(conditionCreatedCheck()) setCreatedAtEnd(NewDate);
  };
  const setAllCreatedDate = (date: string) => {
    setCreatedReset(date);
    setCreatedAtStart(date);
    setCreatedAtEnd(date);
  }
  const conditionCreatedCheck = () => {
    if(createdAtStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: palette.primary.main,
        text: "Please select Created Start"
      });
      setCreatedReset("");
      setCreatedAtEnd("");
      return false
    } else {
      return true
    }
  }
  const setCreatedReset = (date: string) => {
    reset({
      serviceName: serviceName,
      endpointUrl: endpointUrl,
      createdAtStart: date,
      createdAtEnd: date,
      updatedAtStart: updatedAtStart,
      updatedAtEnd: updatedAtEnd,
    });
  }

  const handleUpdatedAtStart = (e: any) => {
    let NewDate: any
    if (e !== null) {
        NewDate = dayjs(e).format("YYYY-MM-DD");
        if(dayjs(e).isAfter(dayjs(updatedAtEnd))) {
          setAllUpdatedDate(NewDate);
        } else {
          setUpdatedAtStart(NewDate);
        }
    } else {
        NewDate = ''
        setAllUpdatedDate(NewDate);
    }
  };
  const handleUpdatedAtEnd = (e: any) => {
    let NewDate: any
    if (e !== null) {
        NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
        NewDate = ''
    }
    if(conditionUpdatedCheck()) setUpdatedAtEnd(NewDate);
  };
  const setAllUpdatedDate = (date: string) => {
    setUpdatedReset(date);
    setUpdatedAtStart(date);
    setUpdatedAtEnd(date);
  }
  const conditionUpdatedCheck = () => {
    if(updatedAtStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: palette.primary.main,
        text: "Please select Updated Start"
      });
      setUpdatedReset("");
      setUpdatedAtEnd("");
      return false
    } else {
      return true
    }
  }
  const setUpdatedReset = (date: string) => {
    reset({
      serviceName: serviceName,
      endpointUrl: endpointUrl,
      createdAtStart: createdAtStart,
      createdAtEnd: createdAtEnd,
      updatedAtStart: date,
      updatedAtEnd: date,
    });
  }

  useDebouncedEffect(
    () => setSearchFilterDebounced(serviceName),
    [serviceName],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(endpointUrl),
    [endpointUrl],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(createdAtStart),
    [createdAtStart],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(createdAtEnd),
    [createdAtEnd],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(updatedAtStart),
    [updatedAtStart],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(updatedAtEnd),
    [updatedAtEnd],
    500
  );

  useEffect(() => {
    setParams({
      ...params,
      serviceName: serviceName,
      endpointUrl: endpointUrl,
      createdAtStart: createdAtStart,
      createdAtEnd: createdAtEnd,
      updatedAtStart: updatedAtStart,
      updatedAtEnd: updatedAtEnd,
    });
    dispatch(setPageKey(serviceName || endpointUrl || createdAtStart || createdAtEnd || updatedAtStart || updatedAtEnd))
  }, [searchFilterDebounced]);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
      <InputForm
        name="serviceName"
        disabled={false}
        errors={errors}
        control={control}
        required
        placeholder="Service Name"
        maxLength={100}
        isLoading={false}
        onChange={handleServiceName}
      />
      <InputForm
        name="endpointUrl"
        disabled={false}
        errors={errors}
        control={control}
        required
        placeholder="Endpoint Url"
        maxLength={100}
        isLoading={false}
        onChange={handleEndpointUrl}
      />
      <DatePickerForm
        name="createdAtStart"
        defaultValue={null}
        control={control}
        errors={errors}
        placeholder="Created Start"
        disabled={false}
        isLoading={false}
        required
        onChange={handleCreatedAtStart}
      />
      <DatePickerForm
        name="createdAtEnd"
        defaultValue={null}
        control={control}
        errors={errors}
        placeholder="Created End"
        disabled={false}
        isLoading={false}
        required
        minDate={(new Date(createdAtStart).getTime())}
        onChange={handleCreatedAtEnd}
      />
      <DatePickerForm
        name="updatedAtStart"
        defaultValue={null}
        control={control}
        errors={errors}
        placeholder="Updated Start"
        disabled={false}
        isLoading={false}
        required
        onChange={handleUpdatedAtStart}
      />
      <DatePickerForm
        name="updatedAtEnd"
        defaultValue={null}
        control={control}
        errors={errors}
        placeholder="Updated End"
        disabled={false}
        isLoading={false}
        required
        minDate={(new Date(updatedAtStart).getTime())}
        onChange={handleUpdatedAtEnd}
      />
    </Box>
  );
};

export default ServiceMngSearch;
