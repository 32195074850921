import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../service/instance";
import Swal from "sweetalert2";

interface IParams {
  isLoadingParams: boolean;
  dataParams: any;
  dataParamPage: any;
  msgParams: string;
  typeIdParams: string;
  optParentParam: any;
  optParentParamFormPage: any;
  optParamId: any;
  dataParamId: any;
  frmTypeId: number;
  paramIdCreateP: number;
  dataUpdateFormParam: any;
}

const initialState: IParams = {
  isLoadingParams: false,
  dataParams: [],
  msgParams: "",
  dataParamPage: [],
  typeIdParams: "",
  optParentParam: [],
  optParamId: [],
  dataParamId: [],
  optParentParamFormPage: [],
  frmTypeId: 0,
  paramIdCreateP: 0,
  dataUpdateFormParam: [],
};

export const getParams = createAsyncThunk<any, any, { rejectValue: any }>(
  "params/list",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.formType.uc_adm_pge_access_uid;
      const resp: any = await instance.get("/form-params", {
        params: data,
        headers: {
          'page-access-uid': uid,
        },
      });
      // console.log("data form group ========> ", resp.data);
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// get params for list table

export const getParamsById = createAsyncThunk<any, any, { rejectValue: any }>(
  "params/getById",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.formType.uc_adm_pge_access_uid;
      const resp: any = await instance.get(`/form-param-by-type-id/${data}`, {
        headers: {
          'page-access-uid': uid,
        },
      });
      // console.log("data form group terbaru ========> ", data);
      console.log(
        "response ========> ",
        resp.data.data.map((e: any) => ({
          label: e.uc_frm_param_name,
          value: e.uc_frm_param_id,
        }))
      );
      let rspData = resp.data.data;
      let response = rspData.map((e: any) => ({
        label: e.uc_frm_param_name,
        value: e.uc_frm_param_id,
      }));
      return { rspData, response };
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// get params dropdown on param page

export const getParamsByIdForParamForm = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("params/getByIdForParamForm", async (data, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
    const uid = state?.user.formType.uc_adm_pge_access_uid;
    const resp: any = await instance.get(`/form-param-by-type-id/${data}`, {
      headers: {
        'page-access-uid': uid,
      },
    });
    // console.log("data form group terbaru ========> ", data);
    // console.log("response ========> ", resp.data );
    return resp.data.data.map((e: any) => ({
      label: e.uc_frm_param_name,
      value: e.uc_frm_param_id,
    }));
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const getParamsId = createAsyncThunk<any, any, { rejectValue: any }>(
  "params/getId",
  async (data, { rejectWithValue, getState }) => {
    try {
      console.log('from param here ===> ')
      const state: any = getState();
      const uid = state?.user.formType.uc_adm_pge_access_uid;
      const resp: any = await instance.get(`/form-param/${data}`, {
        headers: {
          'page-access-uid': uid,
        },
      });
      // console.log("data form group ========> ", data);
      console.log("response dari getparamsid ========> ", resp);
      return resp.data;
    } catch (err) {
      console.log(err);
      throw rejectWithValue(err);
    }
  }
);

// update means for soft deleteing params id

export const updateParamsId = createAsyncThunk<any, any, { rejectValue: any }>(
  "params/updateParamId",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.formType.uc_adm_pge_access_uid;
      const { uc_frm_param_status, uc_frm_param_id, uc_frm_type_id } = data;
      const resp: any = await instance.put(
        `/form-param/${uc_frm_param_id}`,
        {
          uc_frm_param_status,
          uc_frm_type_id,
        },
        {
          headers: {
            'page-access-uid': uid,
          },
        }
      );
      // console.log("data form group ========> ", `/form-param/${uc_frm_param_id}`);
      // console.log(' ===============> ', data);
      // console.log("response ========> ", resp.data );
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// create form params
export const createParamsId = createAsyncThunk<any, any, { rejectValue: any }>(
  "params/createParamId",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.formType.uc_adm_pge_access_uid;
      // const {uc_frm_param_status, uc_frm_param_id, uc_frm_type_id} = data
      const resp: any = await instance.post(`/form-param`, data, {
        headers: {
          'page-access-uid': uid,
        },
      });
      // console.log("data form group ========> ", `/form-param/${uc_frm_param_id}`);
      // console.log(' ===============> ', data);
      // console.log("response ========> ", resp.data );
      if (resp.data.status_code === 201) {
        Swal.fire({
          icon: "success",
          title: "Success create form param",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// update form param with ID
export const updateFormParamsId = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>(
  "params/updateFormParamId",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      // const {uc_frm_param_status, uc_frm_param_id, uc_frm_type_id} = data
      const state: any = getState();
      const uid = state?.user.formType.uc_adm_pge_access_uid;
      const resp: any = await instance.put(
        `/form-param/${data.uc_frm_param_uid}`,
        data,
        {
          headers: {
            'page-access-uid': uid,
          },
        }
      );
      // console.log("data form group ========> ", `/form-param/${uc_frm_param_id}`);
      console.log(" ===============> ", data);
      console.log("response ========> ", resp.data);
      if (resp.data.status_code === 201) {
        Swal.fire({
          icon: "success",
          title: "Success updated form param",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const paramsSlice = createSlice({
  name: "params",
  initialState,
  reducers: {
    setTypeIdParams: (state, action) => {
      state.typeIdParams = action.payload;
    },
    resetDataParamId: (state) => {
      state.dataParamId = [];
      state.optParentParamFormPage = [];
    },
    setFormTypeId: (state, action) => {
      state.frmTypeId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getParams.pending, (state) => {
        state.isLoadingParams = false;
      })
      .addCase(getParams.fulfilled, (state, action) => {
        state.dataParams = action.payload.data.data;
      })
      .addCase(getParams.rejected, (state) => {
        state.isLoadingParams = false;
      })
      .addCase(getParamsById.pending, (state) => {
        state.isLoadingParams = false;
      })
      .addCase(getParamsById.fulfilled, (state, action) => {
        // let paramById = action.payload.data
        // console.log('parm id addcase ===> ', action.payload.data)
        // state.dataParamPage = paramById
        // state.optParentParam = paramById.map((e: any)=> ({label: e.uc_frm_param_name, value: e.uc_frm_param_id}))
        // console.log('action payload ===> ', action.payload)
        state.dataParamPage = action.payload.rspData;
        state.optParentParam = action.payload.response;
      })
      .addCase(getParamsById.rejected, (state) => {
        state.isLoadingParams = false;
      })
      .addCase(getParamsByIdForParamForm.pending, (state) => {
        state.isLoadingParams = true;
      })
      .addCase(getParamsByIdForParamForm.fulfilled, (state, action) => {
        state.isLoadingParams = false;
        // let paramById = action.payload.data
        // state.optParentParamFormPage = paramById.map((e: any)=> ({label: e.uc_frm_param_name, value: e.uc_frm_param_id}))
        state.optParentParamFormPage = action.payload;
      })
      .addCase(getParamsByIdForParamForm.rejected, (state) => {
        state.isLoadingParams = false;
      })
      .addCase(getParamsId.pending, (state) => {
        state.isLoadingParams = false;
      })
      .addCase(getParamsId.fulfilled, (state, action) => {
        let paramId = action.payload.data;
        state.dataParamId = paramId;
        // console.log(' param by param id ==> ', action.payload.data)
        // state.optParamId = paramId.map((e: any)=> ({label: e.uc_frm_param_name, value: e.uc_frm_param_id}))
      })
      .addCase(getParamsId.rejected, (state) => {
        state.isLoadingParams = false;
      })
      .addCase(createParamsId.pending, (state) => {
        state.isLoadingParams = false;
      })
      .addCase(createParamsId.fulfilled, (state, action) => {
        // console.log('createParamsId ====> ', action.payload.data.uc_frm_param_id)
        state.paramIdCreateP = action.payload.data.uc_frm_param_id;
      })
      .addCase(updateFormParamsId.pending, (state) => {
        state.isLoadingParams = true;
      })
      .addCase(updateFormParamsId.fulfilled, (state, action) => {
        state.dataUpdateFormParam = action.payload.data;
      })
      .addCase(updateFormParamsId.rejected, (state) => {
        state.isLoadingParams = false;
      });
  },
});

export const { setTypeIdParams, resetDataParamId, setFormTypeId } =
  paramsSlice.actions;
export default paramsSlice.reducer;
