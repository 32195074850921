

export  const optFieldType = [
    {
      label: "UnEditable Field",
      value: "00",
    },
    {
      label: "Input Text Field",
      value: "01",
    },
    {
      label: "Dropdown",
      value: "02",
    },
    {
      label: "Multiple Choice",
      value: "03",
    },
    {
      label: "Checkbox",
      value: "04",
    },
    {
      label: "Textbox",
      value: "05",
    },
    {
      label: "Separator",
      value: "06",
    },
    {
      label: "Button",
      value: "07",
    },
    {
      label: "Radio Button",
      value: "08",
    },
    {
      label: "Multiple Checkbox",
      value: "09",
    },
    {
      label: "Multiple Radio Button",
      value: "10",
    },
    {
      label: "Grid",
      value: "11",
    },
  ];