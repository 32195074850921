import { Box } from '@mui/system';
import { UserData } from '../../utils/datajson/datachart';
import { Chip, Paper, Typography } from '@mui/material';
const Dashboard = () => {

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        // alignItems: 'center',
        flexDirection: 'column',
        // backgroundColor: 'red',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '3rem',
          '& > :not(style)': {
            m: 1,
          },
        }}
      >
        <Paper elevation={24} sx={{ p: 4 }}>
          <Typography variant='h1'>Hi Admin Test</Typography>
          <Typography variant='h5'>Status: 
                  <Chip 
                    label="SUPERADMIN"
                    variant="outlined"
                    color="primary"
                    sx={{ borderRadius: 0 }}
                  /></Typography>
          <Typography variant='h5'>10 January 2023 | Have a great day!</Typography>

        </Paper>

      </Box>
    </Box>
  );
};

export default Dashboard;
