import { Button, Grid } from '@mui/material';
import { InputForm } from '../../components/molecules/forms';
import DndReact from './dnd/index';

const OptionValueDnd = ({
  errors,
  control,
  defaultValues,
  setDefaultValues,
  watch,
  setValue,
}: any) => {
  const saveDefaultValue = () => {
    setDefaultValues([
      ...defaultValues,
      {
        label: watch('optValue'),
        value: watch('optValue'),
        isDeleted: false
      },
    ]);
    setValue('optValue', '');
  };

  return (
    <>
      <Grid direction="row" container columnGap="20px" alignItems="center">
        <Grid xs={7}>
          <InputForm
            name="optValue"
            label="Option Value"
            placeholder="None"
            disabled={false}
            isLoading={false}
            control={control}
            errors={errors}
            required
          />
        </Grid>
        <Grid xs={4}>
          <Button variant="contained" color="gray" onClick={saveDefaultValue}>
            Add row
          </Button>
        </Grid>
      </Grid>
      <DndReact
        defaultValues={defaultValues}
        setDefaultValues={setDefaultValues}
        setValue={setValue}
      />
    </>
  );
};

export default OptionValueDnd;
