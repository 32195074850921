import { useTheme, FormLabel } from '@mui/material';

export default function InputLabel(props: any) {
  const { palette } = useTheme();
  const { children, sx } = props;
  return (
    <FormLabel
      {...props}
      sx={{
        ...sx,
        display: 'flex',
        '& .MuiFormLabel-asterisk': {
          color: palette.secondary.main,
        },
        color: palette.neutral.dark,
        fontFamily: 'Open Sans',
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '1.5rem',
      }}
    >
      {children}
    </FormLabel>
  );
}
