import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import FormGroupSearch from "../../components/molecules/formGroup/FormGroupSearch";
import FormGroupTable from "../../components/molecules/formGroup/FormGroupTable";
import { useNavigate } from "react-router-dom";
import { getFormGroup, rmvMsgSccsFGr } from "../../store/feature/formGroup";

// icon
import AddIcon from "@mui/icons-material/Add";
import Toast from "../../components/atoms/toast";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";

const FormGroup = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});

  const { dataFGroup, isLoadingGroup, isLoadingEvent, msgSccsFGr } =
    useAppSelector((state: RootState) => state.fGroup);
  const { formGroup } = useAppSelector((state: RootState) => state.user);

  const handleCloseToast = () => {
    dispatch(rmvMsgSccsFGr());
  };

  useEffect(() => {
    if (Object.keys(formGroup).length !== 0) {
    dispatch(getFormGroup(params));
    }
  }, [params, formGroup]);

  return (
    <ListDataSkeleton
      isLoading={isLoadingGroup}
      isLoadingEvent={isLoadingEvent}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}
        >
          Form Group
        </Typography>

        {formGroup.uc_adm_pge_acs_create === 1 && (
          <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/form-group/create")}
              sx={{ marginBottom: "18px" }}
            >
              <AddIcon /> Create
            </Button>
          </Box>
        )}
        <FormGroupSearch params={params} setParams={setParams} />
        <FormGroupTable
          data={dataFGroup}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
        <Toast
          severity="success"
          open={msgSccsFGr !== ""}
          handleClose={handleCloseToast}
          message={msgSccsFGr}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default FormGroup;
