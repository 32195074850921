import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../service/instance";
import Swal from "sweetalert2";

interface IFormGroup {
  isLoadingGroup: boolean;
  isLoadingEvent: boolean;
  dataFGroup: any;
  editDataFGroup: any;
  formGroupSelect: any;
  totalRecFGroup: any;
  msgSccsFGr: string;
}

const initialState: IFormGroup = {
  isLoadingGroup: false,
  isLoadingEvent: false,
  dataFGroup: [],
  editDataFGroup: {},
  formGroupSelect: [],
  totalRecFGroup: 0,
  msgSccsFGr: "",
};

export const getFormGroupId = createAsyncThunk<any, any, { rejectValue: any }>(
  "formGroup/list/id",
  async (id, { rejectWithValue, getState }) => {
    try {
      const state : any = getState()
      const uid = state?.user.formGroup.uc_adm_pge_access_uid
      const resp: any = await instance.get(`/form-group?formGroupUID=${id}`, {
        headers: {
          'page-access-uid': uid
        }
      });
      console.log(id, ' =============> form data')
      console.log(resp.data, " ========> data form group");
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const getFormGroup = createAsyncThunk<any, any, { rejectValue: any }>(
  "formGroup/list",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state : any = getState()
      const uid = state?.user.formGroup.uc_adm_pge_access_uid
      const resp: any = await instance.get("/form-groups", {
        params: data,
        headers: {
          'page-access-uid': uid
        }
      });
      // console.log(data, ' =============> form data')
      // console.log(resp.data, " ========> data form group");
      let formGroupData = resp.data.data
      let formGroupList = formGroupData.data.map((e: any) => ({
        label: e.uc_frm_grp_name,
        value: e.uc_frm_grp_id,
      }));
      let totalRecord = formGroupData.total_records;
      return {formGroupData, formGroupList, totalRecord};
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const createFormGroup = createAsyncThunk<any, any, { rejectValue: any }>(
  "formGroup/create",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state : any = getState()
      console.log('from state ===> ', state?.user.formGroup);
      const uid = state?.user.formGroup.uc_adm_pge_access_uid
      const resp: any = await instance.post("/form-group", data, {
        headers: {
          'page-access-uid': uid
        }
      });
      // console.log(data, " ===========> isi data");
      // console.log(resp.data, " ========> form response group");
      dispatch(getFormGroup({pagesize: 10}))
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);
export const updateFormGroup = createAsyncThunk<any, any, { rejectValue: any }>(
  "formGroup/update",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state : any = getState()
      const uid = state?.user.formGroup.uc_adm_pge_access_uid
      const resp = await instance.put(
        `/form-group?formGroupUID=${data.uc_frm_grp_uid}`,
        data, {
          headers: {
            'page-access-uid': uid
          }
        }
      );
      // console.log(data, " ================> data from edit page");
      // console.log(resp.data, " ========> data form edit group");
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);
export const deleteFormGroup = createAsyncThunk<any, any, { rejectValue: any }>(
  "formGroup/delete",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const state : any = getState()
      const uid = state?.user.formGroup.uc_adm_pge_access_uid
      const resp = await instance.delete(`/form-group?formGroupUID=${data}`, {
        headers: {
          'page-access-uid': uid
        }
      });
      console.log("data form delete group ========> ", resp.data);
      dispatch(getFormGroup({pagesize: 10}));
      return resp.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const formGroupSlice = createSlice({
  name: "formGroup",
  initialState,
  reducers: {
    rmvMsgSccsFGr: (state) => {
      state.msgSccsFGr = "";
    },
  },
  extraReducers(builder) {
    builder

      .addCase(getFormGroup.pending, (state) => {
        state.isLoadingGroup = true;
      })
      .addCase(getFormGroup.fulfilled, (state, action) => {
        state.isLoadingGroup = false;
        const { formGroupData, formGroupList, totalRecord } = action.payload
        state.dataFGroup = formGroupData.data;
        // let fromGrList = action.payload.data;
        state.formGroupSelect = formGroupList
        // .map((e: any) => ({
        //   label: e.uc_frm_grp_name,
        //   value: e.uc_frm_grp_id,
        // }));
        state.totalRecFGroup = totalRecord
      })
      .addCase(getFormGroup.rejected, (state) => {
        state.isLoadingGroup = false;
      })

      .addCase(createFormGroup.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(createFormGroup.fulfilled, (state, action) => {
        state.isLoadingEvent = false
        state.msgSccsFGr = action.payload?.msg;
      })
      .addCase(createFormGroup.rejected, (state)=> {
        state.isLoadingEvent = false
      })

      .addCase(updateFormGroup.pending, (state)=> {
        state.isLoadingEvent = true;
      })
      .addCase(updateFormGroup.fulfilled, (state, action)=> {
        state.isLoadingEvent = false
        state.msgSccsFGr = action.payload?.msg
      })
      .addCase(updateFormGroup.rejected, (state)=> {
        state.isLoadingEvent = false
      })

      .addCase(deleteFormGroup.pending, (state)=> {
        state.isLoadingEvent = true;
      })
      .addCase(deleteFormGroup.fulfilled, (state, action)=> {
        state.isLoadingEvent = false;
        Swal.fire({
          title: "Deleted!",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: '#051438',
          text:"Your file has been deleted.",
          didOpen: () => Swal.getConfirmButton()?.focus()
        })
      })
      .addCase(deleteFormGroup.rejected, (state, action)=> {
        state.isLoadingEvent = false;
        Swal.fire({
          title: "Error!",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: '#051438',
          text:"error delete.",
          didOpen: () => Swal.getConfirmButton()?.focus()
        })
      })

      .addCase(getFormGroupId.pending, (state)=> {
        state.isLoadingGroup = true
      })
      .addCase(getFormGroupId.fulfilled, (state, action)=> {
        state.isLoadingGroup = false
        state.editDataFGroup = action.payload.data
      })
      .addCase(getFormGroupId.rejected, (state)=> {
        state.isLoadingGroup = false
      })
  },
});

export const { rmvMsgSccsFGr } = formGroupSlice.actions;
export default formGroupSlice.reducer;
