import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { useEffect, useState } from "react";

// Icons
import AddIcon from "@mui/icons-material/Add";
import ServiceMngSearch from "../../components/molecules/serviceMngmnt/ServiceMngSearch";
import ServiceMngTable from "../../components/molecules/serviceMngmnt/ServiceMngTable";
import { getServMngTable } from "../../store/feature/servMng";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";

const ServManag = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({ pagesize: 10 });
  const [defaultValue, setDefaultValue] = useState({});
  const { dataServMng, isLoadingServMng, totalRecServMng } = useAppSelector(
    (state: RootState) => state.servMng
  );
  const { srvMng } = useAppSelector((state: RootState) => state.user);
  const { isLoadingEvent } = useAppSelector((state: RootState) => state.fGroup);

  useEffect(() => {
    if (Object.keys(srvMng).length !== 0) {
      dispatch(getServMngTable(params));
    }
  }, [params, srvMng]);

  console.log("dataServMng ===> ", dataServMng);

  return (
    <ListDataSkeleton
      isLoading={isLoadingServMng}
      isLoadingEvent={isLoadingEvent}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}
        >
          Service Management
        </Typography>
        {srvMng.uc_adm_pge_acs_create === 1 && (
          <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/service-management/create")}
              sx={{ marginBottom: "18px", flexDirection: "flex-end" }}
            >
              <AddIcon /> Create
            </Button>
          </Box>
        )}
        <ServiceMngSearch params={params} setParams={setParams} />
        <ServiceMngTable
          data={dataServMng}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default ServManag;
