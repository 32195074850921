import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { IconButton } from '@mui/material';
// import { IconInfo, IconSuccess, IconClose } from '../Icon';

// icon
import InfoIcon from '@mui/icons-material/Info';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

interface propsI {
  open: boolean;
  handleClose: (event?: React.SyntheticEvent | Event) => void;
  message: string;
  severity?: 'success' | 'error' | 'info' | 'warning';
  title?: string;
}
export default function Toast({
  open,
  handleClose,
  message,
  severity = 'success',
  title,
}: propsI) {
  const onClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    handleClose();
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <DisabledByDefaultIcon />
          </IconButton>
        }
        icon={severity === 'success' ? <CheckBoxIcon /> : <InfoIcon />}
        onClose={handleClose}
        severity={severity}
        sx={{
          width: '100%',
          '.MuiAlert-icon': {
            display: 'flex',
            paddingTop: '11px',
          },
          '.MuiAlert-action': {
            display: 'flex',
          },
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '24px',
              marginBottom: title && '8px',
              color: 'white',
            }}
          >
            {title}
          </div>
          <div
            style={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '28px',
              color: 'white',
            }}
          >
            {message}
          </div>
        </div>
      </Alert>
    </Snackbar>
  );
}
