import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./feature/user";
import formGroup from "./feature/formGroup";
import formType from "./feature/formType";
import params from "./feature/params";
import optionValue from "./feature/optionValue";
import endUser from "./feature/endUser";
import servMng from "./feature/servMng";

export const store = configureStore({
  reducer: {
    user: userReducer,
    fGroup: formGroup,
    fType: formType,
    param: params,
    optVal: optionValue,
    endUser: endUser,
    servMng: servMng
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
