import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { makeStyles, useTheme } from "@mui/material/styles";
import { IOpenMainProps } from "../../../interfaces/IUtils";
import { useNavigate } from "react-router-dom";
import { arrSidebar } from "../../../constants/SidebarRouteName";

// Image
import korlantasLogo from "../../../assets/logo/korlantas-logo.png";

// Icon
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

//Two tone Icon
import { ISidebar } from "../../../interfaces/ISidebar";
import { useState } from "react";
import { RootState, useAppSelector } from "../../../store";

const Sidebar = ({ open, setOpen }: IOpenMainProps) => {
  const drawerWidth = 240;
  const navigate = useNavigate();
  const theme = useTheme();
  const [selected, setSelected] = useState(-1);
  const { formType, formGroup, srvMng } = useAppSelector(
    (state: RootState) => state.user
  );

  const formTypeName = formType !== undefined ? "form-type" : "";
  const formGroupName = formGroup !== undefined ? "form-group" : "";
  const servMngName = srvMng !== undefined ? "service-management" : "";

  const tempSidebar = [formTypeName, formGroupName, servMngName];

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openedMixin = () => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = () => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const handleSelectedClick = (index: number) => {
    setSelected(index);
  };

  return (
    <MuiDrawer
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.primary.main,
        },
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
          ...openedMixin(),
          "& .MuiDrawer-paper": openedMixin(),
        }),
        ...(!open && {
          ...closedMixin(),
          "& .MuiDrawer-paper": closedMixin(),
        }),
      }}
      variant="permanent"
    >
      <Box
        sx={{
          backgroundColor: `${theme.palette.primary.main}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: theme.spacing(0, 1),
          // necessary for content to be below app bar
          ...theme.mixins.toolbar,
        }}
      >
        {open && (
          <IconButton
            onClick={handleDrawerClose}
            sx={{ color: theme.palette.white.darker }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        )}
      </Box>
      <List>
        {/* {open && 
        <Stack sx={{alignItems: 'center'}}>
        <img src={korlantasLogo} alt="korlantas logo" style={{width: '170px', height: "170px"}}/>
        <Button variant="contained" color="primary" size="small" sx={{borderRadius: '50px', width: '160px'}}>Admin Test</Button>
        </Stack>
        } */}
        {arrSidebar
          .filter(item => tempSidebar.includes(item.key))
          .map(({ key, name, path, icon }: ISidebar, index) => (
            <ListItem
              key={key}
              disablePadding
              sx={{ display: "block" }}
              onClick={() => navigate(path)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  color:
                    selected === index
                      ? theme.palette.white.main
                      : theme.palette.white.darker,
                }}
                onClick={() => handleSelectedClick(index)}
                selected={selected === index}
                // style={{ backColor: selected === index ? 'red' : 'white' }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color:
                      selected === index
                        ? theme.palette.white.main
                        : theme.palette.white.darker,
                    // color: theme.palette.white.darker
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText
                  primary={name}
                  sx={{
                    opacity: open ? 1 : 0,
                    // color: theme.palette.white.darker,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </MuiDrawer>
  );
};

export default Sidebar;
