import { Box, Skeleton, Modal, CircularProgress } from '@mui/material';
import React from 'react';

const DetailDataSkeleton = (props: any) => {
  const { isLoading, isLoadingEvent, children, inputCount, titleWidth, isTitle } = props;

  // REGION: TITLE
  const tempTitleWidth = titleWidth == undefined ? '50%' : titleWidth
  const isAddTitle = isTitle == undefined ? true : isTitle

  // REGION: SEARCH-BAR
  const inputLength = inputCount == undefined ? 10 : inputCount
  const inputItems = Array.from({ length: inputLength }, (_, index) => index + 1);

  // REGION: LOADING EVENT STYLE
  const loadingEventStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  };

  return (
    <Box sx={{ position: 'relative' }}>
        {
        isLoadingEvent && <Modal open={true}>
            <Box sx={loadingEventStyle}>
                <CircularProgress />
            </Box>
        </Modal>
        }
        {
        isLoading && <Box sx={{ position: 'absolute', width:'100%', zIndex: 2, backgroundColor:'#FFFFFF' }}>

            {/* REGION: TITLE */}
            {
                isAddTitle == true
                ? <>
                <Box height={'24px'}/>
                <Skeleton variant="rectangular" sx={{ borderRadius: '10px' }} animation="wave" width={tempTitleWidth} height={40}/>
                </> : null
            }

            {/* REGION: CONTENT */}
            {
                inputItems.map((_, index) => { return (
                    <React.Fragment key={index}>
                        <Box height={'28px'}/>
                        <Skeleton variant="rectangular" sx={{ borderRadius: '10px' }} animation="wave" width={'80px'} height={20}/>
                        <Box height={'8px'}/>
                        <Skeleton variant="rectangular" sx={{ borderRadius: '10px' }} animation="wave" width={'100%'} height={40}/>
                    </React.Fragment>
                )})
            }

        </Box>
        }
        <Box sx={{ position: 'absolute', width:'100%', zIndex: 1,  }}> {children} </Box>
    </Box>
    );
};

export default DetailDataSkeleton;
