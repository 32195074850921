import { LOCAL_STORAGE_KEY  } from '../constants'

const { TOKEN } = LOCAL_STORAGE_KEY;

export function setToken(token: string){
  console.log('local storage called ======> ' ,token)
    return localStorage.setItem(TOKEN, token)
}

export function getToken() {
    return localStorage.getItem(TOKEN) || '';
  }


  export function clearStorage() {
    localStorage.removeItem(TOKEN);
  }