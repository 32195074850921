import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
// import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import { setPageKey } from "../../../store/feature/user";
import { useAppDispatch } from "../../../store";

const schema = yup.object({
  title: yup.string().required(),
});

const FormTypesSearch = ({params, setParams}: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  // const [uid, setUid] = useState("");
  const [formTypeName, setFormTypeName] = useState("");
  const [groupName, setGroupName] = useState("")
  const [userActive, setUserActive] = useState("");
  const dispatch = useAppDispatch();

  const {
    control,
    formState: { errors },
  } = useForm<{
    title: string;
    policy: Array<string>;
    isActive: boolean;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      title: "",
      policy: [],
      isActive: false,
    },
  });

  const optSelect = [
    {
      label: "ACTIVE",
      value: "ACTIVE",
    },
    {
      label: "INACTIVE",
      value: "INACTIVE",
    },
  ];

  // const handleUID = (e: string) => {
  //   setUid(e);
  // };
  const handleFormTypeName = (e: any) => {
    setFormTypeName(e);
  };
  const handleGroupName = (e: any) => {
    setGroupName(e);
  };
  const handleStatusSelect = (e: any) => {
    if(e === null ){
      setUserActive('')  
    } else {
      setUserActive(e.value);
    }
  };

  // useDebouncedEffect(() => setSearchFilterDebounced(uid), [uid], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(formTypeName), [formTypeName], 500);
  useDebouncedEffect(
    () => {
      setSearchFilterDebounced(groupName)
    },
    [groupName],
    500
  );
  useDebouncedEffect(
    () => setSearchFilterDebounced(userActive),
    [userActive],
    500
  );

  useEffect(() => {
    setParams({
      ...params,
      // uc_frm_type_uid : uid,
      uc_frm_type_name : formTypeName,
      uc_frm_grp_name : groupName,
      uc_frm_type_status : userActive,
    })
    dispatch(setPageKey(formTypeName || groupName || userActive))
  }, [searchFilterDebounced]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        {/* <InputForm
          name="uid"
          disabled={false}
          errors={errors}
          control={control}
          required
          placeholder="UID"
          maxLength={100}
          isLoading={false}
          onChange={handleUID}
          type="number"
        /> */}
        <InputForm
          name="formTypeName"
          disabled={false}
          errors={errors}
          control={control}
          required
          placeholder="From Type Name"
          maxLength={100}
          isLoading={false}
          onChange={handleFormTypeName}
        />
        <InputForm
          name="groupName"
          disabled={false}
          errors={errors}
          control={control}
          required
          placeholder="Group Name"
          maxLength={100}
          isLoading={false}
          onChange={handleGroupName}
        />
        <SelectForm
          name="status"
          defaultValue={1}
          options={optSelect}
          placeholder="Status"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          isClear
          // sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleStatusSelect}
        />
      </Box>
    </Box>
  );
};

export default FormTypesSearch;
