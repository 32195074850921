import * as yup from "yup";

import {
  validationTextField,
  validationStringSelect,
} from "./defaultValidations";

    //   serviceName: "",
    //   endpointUrl: "",
    //   description: "",
    //   parentService: "",
    //   status: "",
    //   mainForm: "",

export default yup.object({
  // uid: validationTextField("UID", 3, 127),
  serviceName: validationTextField("Service Name", 3, 127),
  endpointUrl: validationTextField("Endpoint Url", 3, 127),
  // description: validationTextField("description", 3, 127),
  // parentService: validationStringSelect("Parent Service"),
  // formTypeParent: validationStringSelect("Form Type Parent"),
  status: validationStringSelect("Status"),
  mainForm: validationStringSelect("Main Form"),
});