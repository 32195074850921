import { useState } from "react";
import { Box, IconButton, Stack, Chip } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./index.css";

// icons
import DeleteIcon from "@mui/icons-material/Delete";

const index = ({ defaultValues, setDefaultValues, setValue }: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const [characters, updateCharacters] = useState<any>(finalSpaceCharacters);

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const items = Array.from(defaultValues);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    console.log("is result ======> ", result, items);
    setDefaultValues(items);
  }

  const handleDeleteIcon = (val: any) => {
    console.log(val);
    const newDefaultValues = defaultValues.map((e: any) => {
      // let dataStatus = false;
      let newVal = Object.assign({}, e)
      if (e.value === val) {
        newVal.isDeleted = true;
      }
      return newVal;
    });
    setDefaultValues(newDefaultValues);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="characters">
        {(provided) => (
          <Box
            className="characters"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {defaultValues.map(
              ({ value, label, isDeleted }: any, index: number) => {
                return (
                  <>
                    {!isDeleted && (
                      <Draggable key={value} draggableId={value} index={index}>
                        {(provided) => (
                          <Stack
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            direction="row"
                            alignItems="center"
                            key={value}
                          >
                            <Chip label={label} sx={{ width: "200px" }} />
                            <IconButton onClick={() => handleDeleteIcon(value)}>
                              <DeleteIcon />
                            </IconButton>
                          </Stack>
                        )}
                      </Draggable>
                    )}
                  </>
                );
              }
            )}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default index;
