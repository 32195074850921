import { Box, Button, Stack } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import PaginationControl from "../pagination/Pagination";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { deleteFormGroup } from "../../../store/feature/formGroup";
import { deleteServMng } from "../../../store/feature/servMng";

const ServiceMngTable = ({params, setParams, defaultValue, setDefaultValue, data}: any) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { totalRecServMng } = useAppSelector((store: RootState)=> store.servMng)
  const customCellRender = (params: GridCellParams) => {
    // Button for Edit
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      // console.log('params from edit page ==> ', params)
      navigate(`/service-management/edit?srvc-definition-uid=${params.row.uid}`);
      // console.log('mana ada ====> ', params.row.uid)
    };
    // Button for Delete
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteServMng(params.row.uid));
        }
      });
    };
    return (
      <Stack spacing={2} direction="row">
        <Button variant="contained" onClick={onEdit}>
          Update
        </Button>
        <Button variant="contained" color="error" onClick={onDelete}>
          Delete
        </Button>
      </Stack>
    );
  };


  const columns: GridColDef[] = [
    { field: "serviceName", headerName: "Service Name", flex: 1, minWidth: 320 },
    { field: "endpointUrl", headerName: "Endpoint Url", flex: 1, minWidth: 320 },
    { field: "createdAt", headerName: "Created At", flex: 1, minWidth: 320 },
    { field: "updatedAt", headerName: "Updated At", flex: 1, minWidth: 320 },
    {
      field: "action",
      headerName: "Action",
      align: 'center',
      headerAlign: 'center',
      flex: 1, minWidth: 320,
      sortable: false,
      renderCell: customCellRender,
    },
  ];

  console.log('data service name ===> ', data)
  const rows = data.map((row: any, index: number)=> ({
    serviceName: row.service_definition_name,
    endpointUrl: row.service_definition_endpoint_url,
    createdAt: row.service_definition_created_at,
    updatedAt:  row.service_definition_updated_at,
    id: row.service_definition_Id,
    uid: row.service_definition_uid
  }))
  // console.log('from rows ===> ', rows);
  // console.log('from data ====> ', data)

  return (
    <>
      <Box sx={{ height: "650px", width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          // autoHeight
        />
      </Box>
      <PaginationControl params={params} setParams={setParams} total={totalRecServMng}/>
    </>
  );
};

export default ServiceMngTable;
